import React from 'react'

import AgreementContent from './AgreementContent'
import CloseButton from './CloseButton'

import styles from './Agreement.module.scss'

interface Props {
  html: string
  onAccept: () => void
  onClose: () => void
  onBack?: () => void
  closeButtonText: string
  goBackButtonText: string
  acceptButtonText: string
}

export default function Agreement({
  html,
  onAccept,
  onClose,
  onBack,
  closeButtonText,
  goBackButtonText,
  acceptButtonText,
}: Props) {
  if (!onBack) {
    onBack = onClose
  }
  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={onClose}
        className={styles.closeButton}
        title={closeButtonText}
        aria-label={closeButtonText}
      >
        <CloseButton
          style={{
            top: '6.5px',
            left: '-10px',
          }}
        />
      </button>
      <AgreementContent html={html} />
      <div className={styles.buttonsContainer}>
        <button type="button" onClick={onClose} className={styles.goBackButton}>
          {goBackButtonText}
        </button>
        <button
          type="button"
          onClick={onAccept}
          className={styles.onAcceptButton}
        >
          {acceptButtonText}
        </button>
      </div>
    </div>
  )
}
