import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import AccountForm from '../content/account-form.types'
import Agreement from './Agreement'
import CheckBox from './CheckBox'

import { Lang, AllMarkdownRemark, Frontmatter } from '../common-types'
import { State, Dispatch, actions } from '../state-actions-reducers'

import styles from './AcceptConditions.module.scss'

interface Props {
  lang: Lang
  accountForm: AccountForm
  state: State
  dispatch: Dispatch
  onAccept: () => void
}

interface AgreementData extends Frontmatter {
  html: string
}

export default function AcceptConditions({
  lang,
  accountForm,
  state,
  dispatch,
  onAccept,
}: Props) {
  const result = useStaticQuery<AllMarkdownRemark>(graphql`
    {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            html
            frontmatter {
              path
              type
              title
              descText
              linkText
              lang
            }
          }
        }
      }
    }
  `)

  const agreements = result.allMarkdownRemark.edges.reduce(
    (agreementsList: AgreementData[], { node }) => {
      if (node.frontmatter.lang !== lang) {
        return agreementsList
      }
      return [...agreementsList, { ...node.frontmatter, html: node.html }]
    },
    []
  )

  const [agreementToShow, setAgreementToShow] = useState<AgreementData | null>()
  const [hilightUnread, setHilightUnread] = useState(false)

  const header = accountForm.agreementHeader[lang]
  const description = accountForm.agreementsDescription[lang]
  const confurmLabel = accountForm.agreementButtonConfurmLabel[lang]
  const confurmButton = accountForm.agreementButtonConfurm[lang]
  const declineButton = accountForm.gotoMainPage[lang]
  const buttonTitle = accountForm.agreementShowBtnTip[lang]
  return (
    <main className={styles.main}>
      {!agreementToShow ? (
        <>
          <h2 className={styles.header}>{header}</h2>
          {agreements.length === 1 ? (
            <AgreementListItem
              state={state}
              buttonTitle={buttonTitle}
              agreement={agreements[0]}
              hilightUnread={hilightUnread}
              setAgreementToShow={setAgreementToShow}
              description={description}
            />
          ) : (
            <>
              <p>{description}:</p>
              <ul className={styles.list}>
                {agreements.map(agreement => {
                  const { type } = agreement
                  return (
                    <li key={type} className={styles.listItem}>
                      <AgreementListItem
                        state={state}
                        buttonTitle={buttonTitle}
                        agreement={agreements[0]}
                        hilightUnread={hilightUnread}
                        setAgreementToShow={setAgreementToShow}
                      />
                    </li>
                  )
                })}
              </ul>
            </>
          )}

          <p className={styles.confurmLabel}>{confurmLabel}</p>

          <div className={styles.buttonsContainer}>
            <a
              role="button"
              href="https://rentaphoto.com"
              className={styles.declineButton}
            >
              {declineButton}
            </a>
            <button
              onClick={() => {
                const allAccepted = agreements.reduce(
                  (prevAccepted, agreement) => {
                    if (prevAccepted) {
                      return state.agreedOn.includes(agreement.type)
                    }
                    return prevAccepted
                  },
                  true
                )
                if (allAccepted) {
                  setHilightUnread(false)
                  onAccept()
                } else {
                  setHilightUnread(true)
                }
              }}
              className={styles.confurmButton}
            >
              {confurmButton}
            </button>
          </div>
        </>
      ) : (
        <Agreement
          html={agreementToShow.html}
          onAccept={() => {
            dispatch({
              type: actions.AGREE_ON_AGREEMENT,
              data: agreementToShow.type,
            })
            setAgreementToShow(null)
          }}
          onClose={() => {
            setAgreementToShow(null)
          }}
          closeButtonText={accountForm.closeButton[lang]}
          goBackButtonText={accountForm.goBackButton[lang]}
          acceptButtonText={accountForm.acceptButton[lang]}
        />
      )}
    </main>
  )
}

interface AgreementListItemProps {
  state: State
  agreement: AgreementData
  description?: string
  hilightUnread: boolean
  setAgreementToShow: (a: AgreementData) => void
  buttonTitle: string
}

function AgreementListItem({
  state,
  agreement,
  hilightUnread,
  description,
  setAgreementToShow,
  buttonTitle,
}: AgreementListItemProps) {
  const highlight = !state.agreedOn.includes(agreement.type) && hilightUnread
  const descriptionClassName = highlight
    ? `${styles.docDescriptionText} ${styles.highlighted}`
    : styles.docDescriptionText
  return (
    <p className={descriptionClassName}>
      <CheckBox
        checked={state.agreedOn.includes(agreement.type)}
        className={styles.checkbox}
      />
      {description && (
        <span className={styles.docDescription}>{description}</span>
      )}
      <span aria-hidden="true" className={styles.docDescription}>
        &nbsp;{agreement.descText}&nbsp;
      </span>
      <button
        type="button"
        title={buttonTitle}
        className={styles.showAgreement}
        aria-label={agreement.title}
        onClick={() => setAgreementToShow(agreement)}
      >
        {agreement.linkText}
      </button>
      &nbsp;
    </p>
  )
}
