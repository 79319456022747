import React, { createContext, useContext, useState, useEffect } from 'react'

import ContextCurrentLocale from '../components/ContextCurrentLocale'
import AccountForm from '../components/IndexPresentationComponent'

const QueryContext = createContext<string | undefined>(undefined)

export const useQueryContext = () => {
  const query = useContext(QueryContext)
  if (query === undefined) {
    throw new Error(
      'To use the url query context you must wrap component with the QueryContextProvider'
    )
  }
  return query
}

export default function IndexPage() {
  const [query, setQuery] = useState('')
  useEffect(() => {
    setQuery(location.search)
  }, [])

  return (
    <QueryContext.Provider value={query}>
      <ContextCurrentLocale.Provider value="rus">
        <AccountForm />
      </ContextCurrentLocale.Provider>
    </QueryContext.Provider>
  )
}
