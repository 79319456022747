import React, { useState, useEffect, useRef } from 'react'
import ReactPhoneInput from 'react-phone-input-2'

import { useDefer } from '../helpers'
import CloseButton from './CloseButton'
import PlusSignIcon from './PlusSignIcon'
import { generateId } from '../helpers'

import 'react-phone-input-2/dist/style.css'

import styles from './PhoneInput.module.scss'

interface Props {
  numbers: string[]
  setNumbers: (s: string[]) => void
  deleteText: string
  addField: string
  countries: {
    [key: string]: string
  }
  label: string
  valid?: boolean
  required?: boolean
}

export default function PhoneInput({
  numbers,
  setNumbers,
  deleteText,
  addField,
  countries,
  label,
  valid,
  required,
}: Props) {
  // There is always must be at least one input field
  const [state, setState] = useState(numbers.length === 0 ? [''] : numbers)

  // Update upper state after some time on changes.
  // We manualy update state when delete field (see delete button onClick handler)
  const [inOnChangeInput, setInOnChangeInput] = useState(false)
  useDefer(
    () => {
      if (inOnChangeInput) {
        setInOnChangeInput(false)
        setNumbers(state)
      }
    },
    [state],
    300
  )
  const [deleting, setDeleting] = useState(false)
  const addFieldBtnClassName = deleting
    ? `${styles.animateDeleting} ${styles.addField}`
    : styles.addField

  const idRef = useRef(generateId(16))
  const [lastInput, setLastInput] = useState(state.length - 1)
  useEffect(() => {
    setLastInput(state.length - 1)
  }, [state.length])

  // By default input is valid
  if (typeof valid === 'undefined') {
    valid === true
  }
  return (
    <section className={styles.container}>
      <label className={styles.label} htmlFor={`${idRef.current}-${lastInput}`}>
        <p className={styles.labelText}>
          {required && <span className={styles.required}>&nbsp;*</span>}
          {label}
        </p>
      </label>
      <ul className={styles.list}>
        {state.map((number, index) => (
          <li key={index} className={styles.listItem}>
            <div
              className={`${!valid && styles.invalid} ${
                styles.inputAndButtonWrapper
              }`.trim()}
            >
              <ReactPhoneInput
                containerClass={`react-tel-input ${styles.inputContainer}`}
                inputClass={styles.input}
                buttonClass={styles.dropDownButton}
                buttonStyle={{
                  border: 'none',
                  borderRadius: 'none',
                  borderRight: '1px solid black',
                }}
                inputExtraProps={{
                  id: `${idRef.current}-${index}`,
                  required: required || false,
                }}
                defaultCountry={'ru'}
                localization={countries}
                value={number}
                onChange={(value: string) => {
                  // Set state with new array of numbers.
                  // New array is a copy of previous one with all indices kept
                  // only current value changed under current index.
                  // It is same as we set array[index] = newValue but instead
                  // mutating old array we get new one.
                  // Get parts of an array without current item
                  const [firsPart, lastPart] = [
                    // part before current item
                    state.slice(0, index),
                    // part after current item
                    state.slice(index + 1),
                  ]
                  setState([...firsPart, value, ...lastPart])
                  setInOnChangeInput(true)
                }}
              />
              <button
                aria-label={deleteText}
                className={styles.deleteButton}
                type="button"
                onClick={() => {
                  // Get parts of an array without current item
                  const [firsPart, lastPart] = [
                    // part before current item
                    state.slice(0, index),
                    // part after current item
                    state.slice(index + 1),
                  ]
                  const newState = [...firsPart, ...lastPart]
                  setNumbers(newState)
                  setState(newState)
                  // Animate deleting
                  setDeleting(true)
                  setTimeout(() => setDeleting(false), 300)
                }}
                disabled={state.length === 1}
              >
                <CloseButton />
              </button>
            </div>
          </li>
        ))}
      </ul>
      <button
        title={addField}
        aria-label={addField}
        disabled={state.length === 12}
        type="button"
        className={addFieldBtnClassName}
        onClick={() => setState([...state, ''])}
      >
        <PlusSignIcon />
      </button>
    </section>
  )
}
