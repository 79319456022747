import countriesRawList from './countries-raw-data'

import { Country } from '../common-types'

export interface Content {
  allCountries: Country[]
}

export const allCountries = countriesRawList.map(c => ({ ...c, id: c._key }))

// Filter countries array for addresses and citizenship fields.
type CountriesTuple = [Country[], Country[]]
export const [countriesList, citizenshipCountriesList] = allCountries.reduce(
  (countriesTuple: CountriesTuple, country) => {
    const [countriesList, citizenshipCountriesList] = countriesTuple
    // Add statelesness citizenship only to the citizenship array
    if (country.id === '-000') {
      citizenshipCountriesList.push(country)
    }
    // Add Antarctic country only to the countries array
    else if (country.id === '010') {
      countriesList.push(country)
    }
    // Add non statlesness and non Arctic to both arrays
    else {
      citizenshipCountriesList.push(country)
      countriesList.push(country)
    }
    return [countriesList, citizenshipCountriesList]
  },
  [[], []]
)

// export default {
//   allCountries,
//   countriesList,
//   citizenshipCountriesList,
// }
