import React, { useState, useReducer, useContext, useEffect } from 'react'

import Seo from './Seo'
import Header from './Header'
import accountForm from '../content/account-form'
import {
  allCountries,
  countriesList,
  citizenshipCountriesList,
} from '../content/countries'
import ContextCurrentLocale from './ContextCurrentLocale'
import AcceptConditions from './AcceptConditions'
import ScreenOne from './ScreenOne'
import ScreenTwo from './ScreenTwo'
import ScreenThree from './ScreenThree'
import ScreenFour from './ScreenFour'
import ScreenFive from './ScreenFive'
import ScreenSix from './ScreenSix'
import ScreenSeven from './ScreenSeven'
import ScreenEight from './ScreenEight'
import {
  getInitialState,
  actions,
  reducer,
  INITIAL_STATE,
} from '../state-actions-reducers'
import { saveState, resetLocalStorage } from '../local-storage'
import { useDefer } from '../helpers'
import { validateStep } from '../validators'
import useToken from '../useTokenCustomHook'
import { SUBMIT_SUCCESS } from '../rentaphoto-server-parameters'

import styles from './IndexPresentationComponent.module.scss'

const TOTAL_STEPS = 9
const INVISIBLE_STEPS = 3

/* REACT COMPONENT */
export default function Index() {
  const lang = useContext(ContextCurrentLocale)
  const [nextStep, setNextStep] = useState(false)
  // Set state
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const [initialState, setInitialState] = useState(state)
  // Try to load saved state
  useEffect(() => {
    const loadedState = getInitialState(window.localStorage)
    setInitialState(initialState)
    dispatch({
      type: actions.REPLACE,
      data: loadedState,
    })
    // dispatch({
    //   type: actions.CURRENT_STEP,
    //   data: 4,
    // })
  }, [initialState])

  // On mount do not show 0 step if user agreed
  useEffect(() => {
    if (state.currentStep === 0 && state.agreed) {
      dispatch({
        type: actions.CURRENT_STEP,
        data: 1,
      })
    }
  }, [state.currentStep, state.agreed])

  // Set userId and token
  useToken(({ userId, formId }) => {
    dispatch({
      type: actions.USER_ID,
      data: userId,
    })
    dispatch({
      type: actions.FORM_ID,
      data: formId,
    })
  }, [])

  // Save state to localStorage on every change
  useDefer(() => saveState(state, localStorage), [state])

  // When trying to change screen, make sure all fields is valid
  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const [strictValidation, setStrictValidation] = useState(false)

  useEffect(() => {
    // Turn on strict validation on step changing
    if (nextStep) {
      // If step is 0, just move
      if (state.currentStep === 0) {
        setStrictValidation(false)
        dispatch({
          type: actions.CURRENT_STEP,
          data: state.currentStep + 1,
        })
        setNextStep(false)
        window.scrollTo(0, 0)
        return
      }
      setStrictValidation(true)
      // If no invalid fields so far, validate
      if (invalidFields.length === 0) {
        const invalidFieldsNames = validateStep(state.currentStep, state)
        // If all fields is valid, change step
        if (invalidFieldsNames.length === 0) {
          setStrictValidation(false)
          dispatch({
            type: actions.CURRENT_STEP,
            data: state.currentStep + 1,
          })
          setNextStep(false)
          window.scrollTo(0, 0)
          return
        }
        // If some fields invalid
        setInvalidFields(invalidFieldsNames)
      }
      // Some fields invalid, so prevent movement
      setNextStep(false)
    }
  }, [invalidFields.length, nextStep, state])

  // Revalidate fields on change
  useDefer(
    () => {
      if (strictValidation) {
        setInvalidFields(validateStep(state.currentStep, state))
      }
    },
    [state, strictValidation],
    300
  )

  // Reset state funcs
  const [aboutToResetState, setAboutToResetState] = useState(false)
  const [warningWasShown, setWarningWasShown] = useState(false)
  useEffect(() => {
    if (aboutToResetState) {
      setWarningWasShown(true)
      setTimeout(() => {
        setAboutToResetState(false)
      }, 10000)
    }
  }, [aboutToResetState])

  function handleResetState() {
    // Reset only if user have seen the warning message
    if (warningWasShown) {
      dispatch({
        type: actions.RESET,
        data: false,
      })
      resetLocalStorage(window.localStorage)
      // This is for uppy reset to work
      // TODO: create uppy reset.
      location.reload()
    } else {
      setAboutToResetState(true)
    }
  }

  /* RENDER */
  const step = state.currentStep
  return (
    <>
      <Seo title={accountForm.title[lang]} />
      <Header
        className={
          state.agreed ? styles.header : `${styles.header} ${styles.headerZero}`
        }
        accountForm={accountForm}
        lang={lang}
        step={step}
      />
      {/* AGREEMENT */}
      {!state.agreed && step !== TOTAL_STEPS ? (
        <AcceptConditions
          lang={lang}
          onAccept={() => {
            setNextStep(true)
            dispatch({ type: actions.AGREED, data: true })
          }}
          accountForm={accountForm}
          state={state}
          dispatch={dispatch}
        />
      ) : (
        <>
          <form
            className={styles.form}
            onSubmit={event => event.preventDefault()}
          >
            {/* NAME AND PASSPORT */}
            {step === 1 && (
              <ScreenOne
                lang={lang}
                state={state}
                initialState={initialState}
                dispatch={dispatch}
                accountForm={accountForm}
                citizenshipCountriesList={citizenshipCountriesList}
                invalidFields={invalidFields}
              />
            )}
            {/* ADDRESES */}
            {step === 2 && (
              <ScreenTwo
                lang={lang}
                state={state}
                initialState={initialState}
                dispatch={dispatch}
                accountForm={accountForm}
                countriesList={countriesList}
                invalidFields={invalidFields}
              />
            )}
            {/* UPLOAD PASSPORT IMAGE */}
            {step === 3 && (
              <ScreenThree
                lang={lang}
                state={state}
                dispatch={dispatch}
                accountForm={accountForm}
                invalidFields={invalidFields}
              />
            )}
            {step === 4 && (
              <ScreenFour
                lang={lang}
                state={state}
                initialState={initialState}
                dispatch={dispatch}
                accountForm={accountForm}
                allCountries={allCountries}
                invalidFields={invalidFields}
              />
            )}
            {step === 5 && (
              <ScreenFive
                lang={lang}
                state={state}
                initialState={initialState}
                dispatch={dispatch}
                accountForm={accountForm}
                allCountries={allCountries}
                invalidFields={invalidFields}
              />
            )}
            {step === 6 && (
              <ScreenSix
                state={state}
                lang={lang}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            )}
            {/* SHOW SUMMARY */}
            {step === 7 && (
              <ScreenSeven
                lang={lang}
                state={state}
                accountForm={accountForm}
                setStep={data =>
                  dispatch({
                    type: actions.CURRENT_STEP,
                    data,
                  })
                }
                allCountries={allCountries}
                dispatch={dispatch}
              />
            )}
          </form>

          {/* SUBMIT LOADING */}
          {step === 8 && (
            // This component resets the state on window
            // close if there is no error.
            <ScreenEight
              lang={lang}
              accountForm={accountForm}
              form={state}
              resetState={() => {
                dispatch({
                  type: actions.RESET,
                  data: false,
                })
                resetLocalStorage(window.localStorage)
              }}
              nextStep={() => {
                // For the sake of employee whishes
                // redirect to plain "success.html"
                // page, where the Subscribe popup
                // will be shown. The popup is auto
                // generated html with some remote
                // scripts so we can't use it here
                dispatch({
                  type: actions.REPLACE,
                  data: { ...INITIAL_STATE, currentStep: 8 },
                })
                resetLocalStorage(window.localStorage)
                window.location.replace(SUBMIT_SUCCESS)
              }}
              prevStep={() => {
                // Turn off strict validation
                setStrictValidation(false)
                // Reset invalid fields
                setInvalidFields([])
                // Move to prev step
                dispatch({
                  type: actions.CURRENT_STEP,
                  data: state.currentStep - 1,
                })
              }}
            />
          )}

          {/* ALMOST THERE (messages and buttons) */}
          {step === TOTAL_STEPS - INVISIBLE_STEPS && (
            <p className={styles.almostThereMessage}>
              {accountForm.almostThereMessage[lang]}
            </p>
          )}

          {state.agreed && step < TOTAL_STEPS - (INVISIBLE_STEPS - 2) && (
            <>
              <div
                className={
                  step !== TOTAL_STEPS - INVISIBLE_STEPS
                    ? styles.buttonsContainer
                    : styles.almostThereButtonsContainer
                }
              >
                {/* PREV BUTTON */}
                <button
                  className={styles.prevButton}
                  disabled={step < 2}
                  type="button"
                  onClick={() => {
                    // Turn off strict validation
                    setStrictValidation(false)
                    // Reset invalid fields
                    setInvalidFields([])
                    // Move to prev step
                    dispatch({
                      type: actions.CURRENT_STEP,
                      data: state.currentStep - 1,
                    })
                  }}
                >
                  {accountForm.prevButton[lang]}
                </button>

                {/* NEXT BUTTON */}
                {step !== TOTAL_STEPS - (INVISIBLE_STEPS - 1) ? (
                  <button
                    className={
                      step === TOTAL_STEPS - INVISIBLE_STEPS
                        ? styles.lastStepButton
                        : styles.nextButton
                    }
                    disabled={
                      step > TOTAL_STEPS - INVISIBLE_STEPS ||
                      invalidFields.length !== 0
                    }
                    type="button"
                    onClick={() => setNextStep(true)}
                  >
                    {/* ALMOST THERE BUTTON */}
                    {step !== TOTAL_STEPS - INVISIBLE_STEPS
                      ? accountForm.nextButton[lang]
                      : accountForm.checkAndSubmitBtn[lang]}
                  </button>
                ) : (
                  /* SUBMIT BUTTON */
                  <button
                    type="button"
                    className={styles.submitButton}
                    onClick={() => setNextStep(true)}
                  >
                    {accountForm.submitButton[lang]}
                  </button>
                )}
              </div>
              {/* RESET STATE BUTTON */}
              {aboutToResetState && (
                <p className={styles.resetWarning}>
                  {accountForm.resetStateWarning[lang]}
                </p>
              )}
              {step !== TOTAL_STEPS - (INVISIBLE_STEPS - 1) && (
                <button
                  className={styles.resetButton}
                  type="button"
                  onClick={handleResetState}
                >
                  {accountForm.resetStateButton[lang]}
                </button>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
