import { StylesConfig } from 'react-select/src/styles'
import { Theme } from 'react-select/src/types'

interface State {
  [key: string]: unknown
}

const styles: StylesConfig = {
  control: (base: StylesConfig, state: State) => {
    const onHover = base['&:hover'] || {}
    return {
      ...base,
      '&:hover': {
        ...onHover,
        borderColor: 'rgb(121,121,121)',
        boxShadow: '0rem 0rem 0.6rem lightgrey',
      },
      minHeight: '2.625rem', // defined in common-and-global.scss
      borderColor: 'rgb(121,121,121)',
      boxShadow: state.isFocused ? '0rem 0rem 0.6rem lightgrey' : 'none',
    }
  },
  input: () => ({}),
  menu: (base: StylesConfig) => ({
    ...base,
    top: '2em',
  }),
  indicatorsContainer: (base: StylesConfig) => ({
    ...base,
    paddingRight: '0.8915354330708661rem',
  }),
  dropdownIndicator: (base: StylesConfig) => ({
    ...base,
    width: '0.8915354330708661rem', // also used in CheckBoxInput.module.scss
    padding: '0px',
  }),
  indicatorSeparator: (base: StylesConfig) => ({
    display: 'none',
  }),
}
export const theme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: '#f15b3a',
    primary: 'rgba(241, 91, 58, 0.8)',
  },
})

export default styles
