import React, { useMemo } from 'react'

import { generateId } from '../helpers'
import { CheckBoxProps } from '../common-types'

import styles from './CheckBoxInput.module.scss'

export default function CheckBoxInbox({
  lang,
  content,
  className,
  onChange,
  value,
}: CheckBoxProps) {
  const id = useMemo(generateId, [])
  return (
    <label htmlFor={id} className={`${className} ${styles.label}`}>
      <div aria-hidden="true" className={styles.checkbox}>
        {value && <div className={styles.checked} />}
      </div>
      <input
        id={id}
        type="checkbox"
        checked={value}
        className={styles.input}
        onChange={() => {
          onChange(!value)
        }}
      />
      <span className={styles.labelText}>&nbsp;{content.label[lang]}</span>
    </label>
  )
}
