import React from 'react'

import styles from './ErrorMessage.module.scss'

interface Props {
  message: string
}
export default function ErrorMessage({ message }: Props) {
  return (
    <div className={styles.container}>
      <p className={styles.message}>{message}</p>
    </div>
  )
}
