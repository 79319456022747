// TODO: Handle server errors. Now user can't proceed if server responded with
// error

import React, { useState, useEffect } from 'react'

import { Lang } from '../common-types'
import {
  State,
  Dispatch,
  actions,
  RUSSIAN_PASSPORT,
} from '../state-actions-reducers'
import AccountForm from '../content/account-form.types'
import UppyDashboard from './UppyDashboard'
import { usePassportUppy } from '../uppyCustomHooks'
import { saveUppyState, UPPY_PASSPORT_IMAGES } from '../local-storage'
import ErrorMessage from './ErrorMessage'

import styles from './ScreenThree.module.scss'

interface Props {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: AccountForm
  invalidFields: string[]
}

/* THE COMPONENT */
function ScreenThree({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
}: Props) {
  const uppy = usePassportUppy(
    lang,
    state.formId,
    dispatch,
    actions.PASSPORT_IMAGE
  )

  const [emptyUppy, setEmptyUppy] = useState(state.passportImages.length === 0)
  useEffect(() => {
    setEmptyUppy(state.passportImages.length === 0)
  }, [state.passportImages.length])

  useEffect(() => {
    if (emptyUppy) {
      const removeBorders = () => setEmptyUppy(false)
      uppy.on('file-added', removeBorders)
      return () => {
        uppy.off('file-added', removeBorders)
      }
    } else {
      const addBorders = () => setEmptyUppy(true)
      uppy.on('file-removed', addBorders)
      return () => {
        uppy.off('file-removed', addBorders)
      }
    }
  }, [emptyUppy, uppy])
  const containerClass = emptyUppy
    ? styles.uploadContainerBorderFull
    : styles.uploadContainerBorderLess
  const wrapperClass = emptyUppy
    ? styles.wrapperBorderFull
    : styles.wrapperBorderLess

  const [header, ...listItems] =
    state.passportType === RUSSIAN_PASSPORT
      ? accountForm.uploadPassportTextRussian[lang].split('%li%')
      : accountForm.uploadPassportTextNonRussian[lang].split('%li%')
  return (
    <>
      <main className={styles.main}>
        <h2 className={styles.header}>
          {accountForm.uploadPassportHeader[lang]}
        </h2>
        <div className={styles.notesContainer}>
          <p className={styles.notesHeader}>{header}</p>
          <ol className={styles.notesList}>
            {listItems.map(li => (
              <li key={li} className={styles.notesListItem}>
                {li}
              </li>
            ))}
          </ol>
        </div>
        <div className={containerClass}>
          <div className={wrapperClass}>
            <UppyDashboard lang={lang} uppy={uppy} />
          </div>
        </div>
        <button
          className={styles.resetButton}
          type="button"
          onClick={() => {
            // First - reset uppy
            uppy.reset()
            // Then save new state to the localStorage
            saveUppyState(UPPY_PASSPORT_IMAGES, uppy.getState())
            // Then update app's state
            dispatch({
              type: actions.PASSPORT_IMAGE_RESET,
              // When reset, data prop is not used. We pass it here just to
              // stop typescript yelling errors.
              data: false,
            })
          }}
        >
          {accountForm.uploadDeleteButton[lang]}
        </button>
      </main>
      {invalidFields.length > 0 && (
        <div className={styles.validationError}>
          <ErrorMessage message={accountForm.validationNoImagesError[lang]} />
        </div>
      )}
    </>
  )
}

export default ScreenThree
