import React from 'react'

import styles from './ErrorScreen.module.scss'
import { Lang } from '../common-types'
import AccountForm from '../content/account-form.types'

interface Props {
  accountForm: AccountForm
  lang: Lang
  onError: () => void
}

const ErrorScreen: React.FC<Props> = ({
  accountForm,
  lang,
  onError,
}: Props) => {
  const [errorMessage, phone, emailRaw] = accountForm.submitError[lang].split(
    '%tel%'
  )
  const [emailText, emailLink] = emailRaw.split('%email%')
  return (
    <div className={styles.error}>
      <p className={styles.errorMessage}>
        {errorMessage}
        <a href={`tel:${phone}`}>{phone}</a>
        {emailText}&nbsp;
        <a href={`mailto:${emailLink}`}>{emailLink}</a>
      </p>
      <a href="https://rentaphoto.com" className={styles.toRentaphotoButton}>
        {accountForm.gotoMainPage[lang]}
      </a>
      <button type="button" onClick={onError} className={styles.stepBackBtn}>
        {accountForm.prevButton[lang]}
      </button>
    </div>
  )
}

export default ErrorScreen
