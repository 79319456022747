import React, { useMemo } from 'react'
import Select from 'react-select'
import { ValueType, ActionMeta } from 'react-select/src/types'

import SelectInputDropdownButton from './SelectInputDropdownButton'
import { CountriesInputProps, Option } from '../common-types'
import reactSelectStyles, { theme } from '../utils/react-select-styles'

import styles from './CountriesInput.module.scss'

type OptionsTuple = [Option[], Option | null]

/* REACT COMPONENT */
function CountriesInput({
  disabled,
  lang,
  onChange,
  initialValue,
  allCountries,
  content,
  className,
  value,
  required,
}: CountriesInputProps) {
  // Get options array and default value with a reducer
  const [options, defaultOption] = useMemo(
    function() {
      return (
        allCountries
          // Sort them in Alphabetical order first
          .sort((a, b) => {
            // -000 is imaginary code for statelesness
            // wich is always first. It's kind of empty field
            if (a.id === '-000') {
              return -1
            }
            if (b.id === '-000') {
              return 1
            }
            return a[lang].localeCompare(b[lang])
          })
          // Then reduce to a tuple of [options, defaultOption]: OptionsTuple
          .reduce(
            function(optionsTuple: OptionsTuple, country): OptionsTuple {
              const [options, defaultOption] = optionsTuple
              const option = {
                value: country.id,
                label: country[lang],
              }
              options.push(option)
              if (!defaultOption && country.id === initialValue) {
                return [options, option]
              }
              return [options, defaultOption]
            },
            [[], null] // Initial value for the reducer
          )
      )
    },
    [allCountries, initialValue, lang]
  )

  function handleChange(
    option: ValueType<Option>,
    { action }: ActionMeta
  ): void {
    // We don't expect array from this Select component
    if (option) {
      option = option as Option
      switch (action) {
        case 'deselect-option':
        case 'remove-value':
        case 'pop-value':
        case 'clear':
          return onChange('')
        case 'select-option':
        case 'set-value':
        default:
          return onChange(option.value)
      }
    }
  }

  // Set value provided from upper component if any
  const currentValue = useMemo(() => {
    if (value) {
      return options.find(option => option.value === value) || options[0]
    }
  }, [value, options])

  return (
    <label className={`${className || ''} ${styles.label}`.trim()}>
      <p className={styles.labelText}>
        {content.label[lang]}
        {required && <span className={styles.required}>&nbsp;*</span>}
      </p>
      <Select
        isDisabled={disabled}
        options={options}
        defaultValue={defaultOption}
        value={currentValue}
        onChange={handleChange}
        blurInputOnSelect
        captureMenuScroll
        className={styles.inputContainer}
        classNamePrefix={styles.inputItem}
        placeholder={content.placeholder[lang]}
        theme={theme}
        styles={reactSelectStyles}
        components={{ DropdownIndicator: SelectInputDropdownButton }}
      />
    </label>
  )
}

export default CountriesInput
