/*
  Define custom hooks that returns Uppy instances. For every upload
  type create new custom hook with custom hook constructor function.
  Exports is at the bottom of the file
*/
import { useEffect, useRef } from 'react'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import uppyRussianLocale from '@uppy/locales/lib/ru_RU'
import uppyEnglishLocale from '@uppy/locales/lib/en_US'

import {
  UPLOADS_ENDPOINT,
  UPLOADS_FIELD_NAME,
} from './rentaphoto-server-parameters'

import {
  getSavedUppyState,
  saveUppyState,
  UppyLocalStorageKey,
  UPPY_PASSPORT_IMAGES,
  UPPY_PORTFOLIO_IMAGES,
} from './local-storage'
import { Dispatch } from './state-actions-reducers'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { Lang } from './common-types'

const ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/bmp',
]
const MAX_FILE_SIZE = 10240000
const MAX_NUMBER_OF_FILES = 10
const MIN_NUMBER_OF_FILES = 1

interface UppyResponse {
  [key: string]: unknown
  status: number
  uploadURL: string
  body: {
    [key: string]: unknown
  }
}
// Custom hook constructor //
/*
  Pass local storage key and action for a dispatcher and function will return
  custom react hook function with UseCustomUppyHook signature. Use this hook
  to get uppy instance inside react components and avoid recreation of uppy
  on every component call. This hook expect dispatcher function, wich will be
  called on every sucessful file upload. Also the uppy instance is already, so
  it may be passed as is to a component.
*/
function createUppyCustomHook(localStorageKey: UppyLocalStorageKey) {
  return (
    lang: Lang,
    formId: string,
    dispatch: Dispatch,
    actionType: symbol
  ): Uppy.Uppy => {
    // Create uppy intstance as on Class.constructor() call
    const uppyRef = useRef<Uppy.Uppy>(
      Uppy({
        // By default russian locale is used
        locale: lang === 'rus' ? uppyRussianLocale : uppyEnglishLocale,
        restrictions: {
          maxFileSize: MAX_FILE_SIZE,
          maxNumberOfFiles: MAX_NUMBER_OF_FILES,
          minNumberOfFiles: MIN_NUMBER_OF_FILES,
          allowedFileTypes: ALLOWED_MIME_TYPES,
        },
        autoProceed: false,
        allowMultipleUploads: true,
      })
    )

    // Configure uppy as on "componentDidMount()"
    useEffect(() => {
      // Set uploader
      uppyRef.current.use(XHRUpload, {
        endpoint: UPLOADS_ENDPOINT,
        method: 'post',
        formData: true,
        fieldName: UPLOADS_FIELD_NAME,
        locale: uppyRussianLocale,
        headers: {
          authorization: `Bearer ${formId}`,
        },
      })

      // Load saved files
      const savedState = getSavedUppyState(localStorageKey)
      if (savedState) {
        uppyRef.current.setState(savedState)
      }

      // Set uploads listener
      function updateState(
        file: Uppy.UploadedUppyFile<{}>,
        { uploadURL }: UppyResponse
      ) {
        if (uploadURL) {
          dispatch({
            type: actionType,
            data: uploadURL,
          })
          saveUppyState(localStorageKey, uppyRef.current.getState())
        }
      }
      uppyRef.current.on('upload-success', updateState)
      return function() {
        uppyRef.current.off('upload-success', updateState)
      }
    }, [])
    return uppyRef.current
  }
}

// Export custom hooks for every upload type
export const usePassportUppy = createUppyCustomHook(UPPY_PASSPORT_IMAGES)
export const usePortfolioUppy = createUppyCustomHook(UPPY_PORTFOLIO_IMAGES)
