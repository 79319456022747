import { validate as emailValidator } from 'email-validator'
import { parseDate, yearsAgo } from './helpers'
import { State, RUSSIAN_PASSPORT } from './state-actions-reducers'

interface VlidateDateArgs {
  current: Date | string
  min?: Date
  max?: Date
  required?: boolean
}

export function validateDate({
  current,
  min,
  max,
  required,
}: VlidateDateArgs): boolean {
  // By default allow empty strings. Make stronger checking on submitting
  const currentValue = parseDate(current)
  if (!currentValue) {
    return !required
  }
  if (min && max) {
    return min <= currentValue && currentValue <= max
  }
  if (min) {
    return min <= currentValue
  }
  if (max) {
    return currentValue <= max
  }
  return true
}

export function validateDatesRange(from: Date | string, to: Date | string) {
  const smallerDate = parseDate(from)
  const biggerDate = parseDate(to)
  if (!smallerDate || !biggerDate) {
    return false
  }
  return smallerDate < biggerDate
}

export function validateCollegeDates(
  startDate: string | Date,
  endDate: string | Date,
  required = false
) {
  if (!startDate || !endDate) return !required
  // Check if dates is valid
  return validateDatesRange(startDate, endDate)
}

// By default allow empty strings
// Will make stronger validation on submitting
export function validateString(
  string: string,
  maxLength: number,
  required?: boolean,
  regexp?: RegExp
): boolean {
  const minLength = required ? 1 : 0
  const trimmedString = string.trim()
  return (
    trimmedString.length >= minLength &&
    trimmedString.length <= maxLength &&
    Boolean(trimmedString.match(regexp || ''))
  )
}

export function validateName(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function birthPlace(place: string, required?: boolean): boolean {
  return validateString(place, 300, required)
}

export function passportDigits(digits: string, required?: boolean): boolean {
  return validateString(digits, 100, required)
}

export function passportText(text: string, required?: boolean): boolean {
  return validateString(text, 400, required)
}

export function validateEmail(text: string, required?: boolean): boolean {
  if (!text && !required) return true
  return validateString(text, 100, required) && emailValidator(text)
}

export function validateReferences(
  values: string[],
  required = false
): boolean {
  if (!required && values.length === 0) return true
  if (required && values.length === 0) return false
  if (values.length < 20) {
    return values.every(value => {
      return validateString(value, 200)
    })
  }
  return false
}

const URL_REGEXP = /^((http(s){0,1}:\/\/(\w|\d)+){1}|\w+(\w|\d)*\.\w)[^\s]*((\w|\d)\.(\w|\d)[^\s]*)*(\w|\d|\/)$/g
export function validateUrl(
  url: string,
  maxLength = 300,
  required = false
): boolean {
  if (!url && !required) return true
  if (url) {
    if (typeof url === 'string') {
      if (url.length < maxLength) {
        return Boolean(url.match(URL_REGEXP))
      }
    }
  }
  return false
}

export function validateSocialLinks(
  values: string[],
  required = false
): boolean {
  if (values.length < 30) {
    return values.every(value => {
      return validateUrl(value, 300, required)
    })
  }
  return false
}

export function validatePhone(value: string): boolean {
  return validateString(value, 24, true) && value.length > 12
}

export function validatePassportNumber(
  value: string,
  isRussian = false,
  required = false,
  minLength?: number,
  maxLength?: number
) {
  if (isRussian) {
    return (
      validateString(value, maxLength || 7, required) &&
      value.length >= (minLength || 3)
    )
  }
  return validateString(value, 200, required)
}

export function validateStep(step: number, state: State): string[] {
  switch (step) {
    case 1:
      return [
        // Array of invalid field names
        // validate(): boolean && 'field name' -> null or 'field name'
        // Then this array filtered with the Boolean()
        !validateName(state.firstName, true) && 'firstName',
        !validateName(state.lastName, true) && 'lastName',
        !validateName(state.patronymic, false) && 'patronymic',
        !validateDate({
          current: state.birthDate,
          min: yearsAgo(130),
          max: yearsAgo(14),
          required: true,
        }) && 'birthDate',
        !birthPlace(state.birthPlace, true) && 'birthPlace',
        !validateString(state.citizenship, 3, true, /^[0-9]{3}$/) &&
          'citizenship',
        !validateString(state.passportType, 300, true) && 'passportType',
        !validatePassportNumber(
          state.passportSeries,
          state.passportType === RUSSIAN_PASSPORT,
          state.passportType === RUSSIAN_PASSPORT,
          4,
          4
        ) && 'passportSeries',
        !validatePassportNumber(
          state.passportNumber,
          state.passportType === RUSSIAN_PASSPORT,
          true,
          7,
          7
        ) && 'passportNumber',
        !passportText(state.passportIssuedBy, true) && 'passportIssuedBy',
        !validateDate({
          current: state.passportIAt,
          min: yearsAgo(130),
          max: new Date(),
          required: true,
        }) && 'passportIAt',
        !validatePassportNumber(
          state.passportAuthorityCode,
          state.passportType === RUSSIAN_PASSPORT,
          state.passportType === RUSSIAN_PASSPORT,
          7,
          7
        ) && 'passportAuthorityCode',
      ].filter(Boolean) as string[]
    case 2:
      // Validate factual address and registered separately
      // and spread the results into array
      return [
        ...[
          'addressFactualCountry',
          'addressFactualCity',
          'addressFactualBuilding',
        ].reduce((invalidFields: string[], fieldName: string) => {
          if (!validateString(state[fieldName] as string, 300, true)) {
            return [...invalidFields, fieldName]
          }
          return invalidFields
        }, []),
        ...[
          'addressRegisteredDate',
          'addressRegisteredCountry',
          'addressRegisteredRegion',
          'addressRegisteredCity',
          'addressRegisteredStreet',
          'addressRegisteredBuilding',
          'addressRegisteredApartments',
        ].reduce((invalidFields: string[], fieldName: string) => {
          if (fieldName === 'addressRegisteredDate') {
            if (
              state.addressRegisteredDate &&
              !validateDate({
                current: state.addressRegisteredDate,
                min: yearsAgo(130),
                max: new Date(),
                required: true,
              })
            ) {
              return [...invalidFields, fieldName]
            }
          }
          if (!validateString(state[fieldName] as string, 300)) {
            return [...invalidFields, fieldName]
          }
          return invalidFields
        }, []),
      ]
    case 3:
      return state.passportImages.filter(Boolean).length > 0
        ? []
        : ['passportImages']
    case 4:
      return [
        !state.emails.some(email => validateEmail(email, true)) && 'emails',
        !state.mobilePhoneNumbers.some(number => validatePhone(number)) &&
          'mobilePhoneNumbers',
        !validateString(state.collegeName, 300, state.isStudent) &&
          'collegeName',
        !validateString(state.collegeProfession, 300, state.isStudent) &&
          'collegeProfession',
        ...(!validateCollegeDates(
          state.collegeStartedAt,
          state.collegeEndedAt,
          state.isStudent
        )
          ? ['collegeStartedAt', 'collegeEndedAt']
          : []),
      ].filter(Boolean) as string[]
    case 5:
      return validateReferences(state.references, true) ? [] : ['references']
    case 6:
    case 7:
    case 8:
    default:
      return []
  }
}

export function validateForm(state: State): string[] {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce(
    (result: string[], step: number): string[] => [
      ...result,
      ...validateStep(step, state),
    ],
    []
  )
}

export function trimForm(state: State): State {
  return Object.keys(state).reduce((trimmedState: State, key) => {
    switch (key) {
      case 'mobilePhoneNumbers':
      case 'addressRegisteredPhones':
      case 'addressFactualPhones':
      case 'workPhoneNumbers':
        // Filter out invalid values
        return {
          ...trimmedState,
          [key]: state[key].filter(validatePhone),
        }
      case 'emails':
        return {
          ...trimmedState,
          emails: state.emails.filter(email => validateEmail(email, true)),
        }
      case 'recommendations':
        return {
          ...trimmedState,
          recommendations: state.recommendations.filter(
            ([name, phone]) => validateName(name, true) && validatePhone(phone)
          ),
        }
      case 'references':
        return {
          ...trimmedState,
          references: state.references.filter(v =>
            validateString(v, 200, true)
          ),
        }
      case 'vk':
      case 'facebook':
      case 'youtube':
      case 'vimeo':
      case 'instagram':
      case 'workWebsite':
      case 'personalWebsite':
      case 'additionalLinks':
        return {
          ...trimmedState,
          [key]: state[key].filter(v => validateUrl(v, 300, true)),
        }
      case 'addressRegisteredDate':
        return {
          ...trimmedState,
          addressRegisteredDate: validateDate({
            current: state.addressRegisteredDate,
            max: new Date(),
          })
            ? state.addressRegisteredDate
            : '',
        }
      case 'isStudent':
        return {
          ...trimmedState,
          collegeStartedAt: state.isStudent ? state.collegeStartedAt : '',
          collegeEndedAt: state.isStudent ? state.collegeEndedAt : '',
        }
      default:
        return trimmedState
    }
  }, state)
}
