/**
 * Here we show loading animation or error message.
 * We submit a form. Then every n seconds send request
 * to the '/status' endpoint to check status of the form.
 * If form is sent successfully, call <onNext> function
 * while still showing the loading animation.
 */

import React, { useState, useEffect, useRef } from 'react'
import axios, { CancelToken } from 'axios'

import ErrorScreen from './ErrorScreen'
import LoadingScreen from './LoadingScreen'
import { Lang } from '../common-types'
import { State } from '../state-actions-reducers'
import AccountForm from '../content/account-form.types'
import { SUBMIT_ENDPOINT, SUBMIT_STATUS } from '../rentaphoto-server-parameters'
import { useQueryContext } from '../pages/index'

import styles from './ScreenEight.module.scss'

// We are waiting for VERIFICATION or ERROR from server
const SUCCESS = 'VERIFICATION'
const ERROR = 'ERROR'

interface Props {
  lang: Lang
  accountForm: AccountForm
  form: State
  resetState: () => void
  nextStep: () => void
  prevStep: () => void
}

interface Response {
  data: {
    ok: boolean
    message?: string
    data?: string
  }
}

interface SubmitArgs {
  form: State
  userId: string
  formId: string
  cancelToken: CancelToken
  onResponse: (status: string) => void
  type: 'SUBMIT' | 'CHECK'
  query?: string
}
async function request({
  form,
  userId,
  formId,
  cancelToken,
  onResponse,
  type,
  // Query string for trackink UTM campaign
  query = '',
}: SubmitArgs) {
  try {
    // Create request function depending on submitting or not
    const requestFn: () => Promise<Response> =
      type === 'SUBMIT'
        ? // if submitting
          () =>
            axios.post(
              SUBMIT_ENDPOINT,
              {
                query,
                userId,
                formId,
                form: {
                  ...form,
                  userId,
                  formId,
                },
              },
              { cancelToken }
            )
        : // if checking status
          () =>
            axios.post(
              SUBMIT_STATUS,
              {
                userId,
                formId,
              },
              { cancelToken }
            )
    // Make request
    const { data } = await requestFn()
    if (!data) throw new Error('Request returned no data')
    const { ok, message, data: status } = data
    if (!ok || !status) {
      const errorMessage = `
Request returned error or no status:
message: ${message}
status: ${status}
`.trim()
      throw new Error(errorMessage)
    }
    // Everything is good
    return onResponse(status)
  } catch (e) {
    // Do nothing on cancelation of the request
    if (axios.isCancel(e)) {
      console.log('Request has been canceled')
      return
    }
    console.error('Error making request:\n', e)
    return onResponse(ERROR)
  }
}

const STATES = {
  SUBMITTING: Symbol('SUBMITTING'),
  CHECKING: Symbol('CHECKING'),
  SCHEDULING: Symbol('SCHEDULING'),
  ERROR: Symbol('ERROR'),
  SUCCESS: Symbol('SUCCESS'),
}

function schedule(callback: () => void, delay = 2000) {
  const timeout = setTimeout(callback, delay)
  return () => {
    clearTimeout(timeout)
  }
}

export default function ScreenEight({
  lang,
  accountForm,
  form,
  resetState,
  nextStep,
  prevStep,
}: Props) {
  const [state, setState] = useState(STATES.SUBMITTING)
  const startDateRef = useRef(new Date().getTime())
  const userIdRef = useRef(form.userId)
  const formIdRef = useRef(form.formId)
  const query = useQueryContext()

  const cancelRequestTokenRef = useRef(axios.CancelToken.source())
  useEffect(() => {
    // Cancel all requests on unmount
    return () => cancelRequestTokenRef.current.cancel()
  }, [])

  useEffect(() => {
    // Reset state if server recieved form and user closes tab
    // If no error and form already submitted, but status not "success" yet:
    if (![STATES.ERROR, STATES.SUBMITTING].includes(state)) {
      window.addEventListener('unload', resetState)
      return () => {
        window.removeEventListener('unload', resetState)
      }
    }
  }, [state])

  useEffect(() => {
    // Check if we not waiting to long
    const tooLong = startDateRef.current + 60000 < new Date().getTime()
    if (tooLong) {
      console.error('Waiting too long')
      return setState(STATES.ERROR)
    }
    // Update userId and formId if they changed
    if (form.formId && form.userId) {
      userIdRef.current = form.userId
      formIdRef.current = form.formId
    }
    const userId = userIdRef.current
    const formId = formIdRef.current

    const cancelToken = cancelRequestTokenRef.current.token

    function onSchedule() {
      setState(STATES.CHECKING)
    }

    function onResponse(status: string) {
      switch (status) {
        case ERROR:
          return setState(STATES.ERROR)
        case SUCCESS:
          return setState(STATES.SUCCESS)
        default:
          return setState(STATES.SCHEDULING)
      }
    }

    switch (state) {
      case STATES.SUBMITTING:
        request({
          form,
          userId,
          formId,
          cancelToken,
          onResponse,
          type: 'SUBMIT',
          query,
        })
        return
      case STATES.CHECKING:
        request({
          form,
          userId,
          formId,
          cancelToken,
          onResponse,
          type: 'CHECK',
          query,
        })
        return
      case STATES.SCHEDULING:
        return schedule(onSchedule)
      case STATES.SUCCESS:
        return nextStep()
      case STATES.ERROR:
        return
      default:
        console.error(`Unexpected state:`, state)
        return setState(STATES.ERROR)
    }
  }, [state, form, startDateRef.current])

  return (
    <main className={styles.main}>
      {state === STATES.ERROR ? (
        <ErrorScreen accountForm={accountForm} lang={lang} onError={prevStep} />
      ) : (
        <LoadingScreen>{accountForm.submitLoading[lang]}</LoadingScreen>
      )}
    </main>
  )
}
