/*
  Here defined all localStorage setters and getters, used in the project
*/
import { State, STATE_REVISION } from './state-actions-reducers'
import { State as UppyState, UploadedUppyFile } from '@uppy/core'
import { reportError } from './helpers'

// Local storage keys
export const MAIN_STATE = 'MAIN_STATE'
export const USER_ID = 'USER_ID'
export const FORM_ID = 'FORM_ID'
export const STEP = 'STEP'

export type UppyLocalStorageKey =
  | 'UPPY_PASSPORT_IMAGES'
  | 'UPPY_PORTFOLIO_IMAGES'
export const UPPY_PASSPORT_IMAGES = 'UPPY_PASSPORT_IMAGES'
export const UPPY_PORTFOLIO_IMAGES = 'UPPY_PORTFOLIO_IMAGES'

// TODO: Handle local storage properly
export function resetLocalStorage(localStorage: Storage) {
  return [
    MAIN_STATE,
    UPPY_PASSPORT_IMAGES,
    UPPY_PORTFOLIO_IMAGES,
    FORM_ID,
  ].forEach(key => localStorage.removeItem(key))
}

export function saveUserId(id: string, localStorage: Storage) {
  return localStorage.setItem(USER_ID, id)
}
export function saveFormId(id: string, localStorage: Storage) {
  return localStorage.setItem(FORM_ID, id)
}
export function saveStep(step: number, localStorage: Storage) {
  return localStorage.setItem(STEP, String(step))
}

export function getSavedUserId(localStorage: Storage) {
  return localStorage.getItem(USER_ID)
}
export function getSavedFormId(localStorage: Storage) {
  return localStorage.getItem(FORM_ID)
}
export function getSavedStep(localStorage: Storage) {
  return parseInt(localStorage.getItem(STEP) || '0', 10)
}
export function saveState(state: State, localStorage: Storage) {
  try {
    localStorage.setItem(MAIN_STATE, JSON.stringify(state))
  } catch (error) {
    // TODO send error to server
    console.error('Cannot save state')
  }
}

export function loadState(initialState: State, localStorage: Storage): State {
  const savedState = localStorage.getItem(MAIN_STATE)
  if (savedState) {
    try {
      const loadedState: State = {
        ...initialState,
        ...JSON.parse(savedState),
      }
      // Return loaded state only if revisions is match
      if (loadedState.revision === STATE_REVISION) {
        return loadedState
      }
      // If revisions do not match, reset local storage
      resetLocalStorage(localStorage)
    } catch (error) {
      reportError({
        message: 'Cannot restore saved state',
        userId: getSavedUserId(localStorage) || 'No user id',
        formId: getSavedFormId(localStorage) || 'No form id',
      })
      console.error('Cannot restore saved state', error)
    }
  }
  return initialState
}

export function saveUppyState(
  storageKey: UppyLocalStorageKey,
  state: UppyState
): void {
  try {
    localStorage.setItem(storageKey, JSON.stringify(state))
    return
  } catch (error) {
    reportError({
      message: 'Cannot save uppy state',
      userId: getSavedUserId(localStorage) || 'No user id',
      formId: getSavedFormId(localStorage) || 'No form id',
    })
    console.error(error)
    return
  }
}

export function getSavedUppyState(
  storageKey: UppyLocalStorageKey
): UppyState | null {
  try {
    const savedState: UppyState = JSON.parse(
      localStorage.getItem(storageKey) || 'null'
    )
    if (savedState) {
      const { files } = savedState
      return {
        ...savedState,
        files: Object.keys(files).reduce((files, id) => {
          const file = files[id] as UploadedUppyFile<{}>
          const updatedFile = { ...file, preview: file.uploadURL }
          return {
            ...files,
            [id]: { ...updatedFile },
          }
        }, files),
      }
    }
    return null
  } catch (error) {
    reportError({
      message: 'Cannot restore saved UPPY state',
      userId: getSavedUserId(localStorage) || 'No user id',
      formId: getSavedFormId(localStorage) || 'No form id',
    })
    console.error(error)
    return null
  }
}
