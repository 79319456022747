import React, { useMemo } from 'react'
import Select from 'react-select'
import { ValueType, ActionMeta } from 'react-select/src/types'

import SelectInputDropdownButton from './SelectInputDropdownButton'
import { SelectInputProps, Option } from '../common-types'
import { generateId } from '../helpers'
import reactSelectStyles, { theme } from '../utils/react-select-styles'

import styles from './SelectInput.module.scss'

function SelectInput({
  disabled,
  lang,
  content,
  noOptionsMessage,
  onChange,
  value,
  options,
  className,
  customInputId,
  components,
}: SelectInputProps) {
  const id = useMemo(generateId, [])
  const optionsList = useMemo(
    () =>
      options.map(option => {
        const value = option[lang] || '—'
        return { value, label: value }
      }),
    [lang, options]
  )
  function handleChange(
    option: ValueType<Option>,
    { action }: ActionMeta
  ): void {
    // We don't expect array from this Select component
    if (option) {
      option = option as Option
      const { value } = option
      if (value) {
        const newValue = optionsList.find(item => value === item.value)
        if (newValue) {
          onChange(newValue.value === '—' ? '' : newValue.value)
        }
      }
    }
  }
  return (
    <label
      htmlFor={customInputId || id}
      className={`${className || ''} ${styles.label}`.trim()}
    >
      <p className={styles.labelText}>{content.label[lang]}</p>
      <Select
        components={{
          DropdownIndicator: SelectInputDropdownButton,
          ...components,
        }}
        inputId={customInputId || id}
        noOptionsMessage={({ inputValue }) =>
          `${inputValue} ${noOptionsMessage[lang]}`
        }
        isDisabled={disabled}
        options={optionsList}
        defaultValue={optionsList[0]}
        value={{
          value,
          label: value,
        }}
        onChange={handleChange}
        blurInputOnSelect
        captureMenuScroll
        className={styles.inputContainer}
        classNamePrefix={styles.inputItem}
        placeholder={content.placeholder[lang]}
        theme={theme}
        styles={reactSelectStyles}
      />
    </label>
  )
}

export default SelectInput
