import React, { useState } from 'react'

import TextInput from './TextInput'
import DatePicker from './DatePicker'
import CountriesInput from './CountriesInput'
import { yearsAgo, isoDate, passportInputHandler } from '../helpers'
import {
  validateName,
  validateDate,
  birthPlace,
  passportDigits,
  passportText,
  validateString,
} from '../validators'
import { Country, ScreenProps } from '../common-types'
import { actions, RUSSIAN_PASSPORT } from '../state-actions-reducers'
import ErrorMessage from './ErrorMessage'
import PassportType from './PassportType'

import styles from './ScreenOne.module.scss'

interface Props extends ScreenProps {
  citizenshipCountriesList: Country[]
}

/* REACT COMPONENT */
function ScreenOne({
  lang,
  state,
  initialState,
  dispatch,
  accountForm,
  citizenshipCountriesList,
  invalidFields,
}: Props) {
  // There is a side effect in onChange listener in PassportType.
  // This side effect dispatch an action to make value for passport number
  // adn passport series valid for russian passport.
  // Search for "PASSPORT SIDE EFFECT".

  const [focus, setFocus] = useState('')
  return (
    <>
      <main className={styles.main}>
        <div className={styles.leftCol}>
          {/* LAST NAME */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.lastName}
            onChange={lastName =>
              dispatch({ type: actions.LAST_NAME, data: lastName })
            }
            value={state.lastName || ''}
            required
            valid={
              validateName(state.lastName) &&
              !invalidFields.includes('lastName')
            }
          />

          {/* FIRST NAME */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.firstName}
            onChange={firstName =>
              dispatch({ type: actions.FIRST_NAME, data: firstName })
            }
            value={state.firstName || ''}
            required
            valid={
              validateName(state.firstName) &&
              !invalidFields.includes('firstName')
            }
          />

          {/* PATRONYMIC */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.patronymic}
            onChange={patronymic =>
              dispatch({ type: actions.PATRONYMIC, data: patronymic })
            }
            value={state.patronymic || ''}
            valid={
              validateName(state.patronymic, false) &&
              !invalidFields.includes('patronymic')
            }
          />

          {/* BIRTH PLACE */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.birthPlace}
            onChange={birthPlace =>
              dispatch({ type: actions.BIRTH_PLACE, data: birthPlace })
            }
            value={state.birthPlace || ''}
            valid={
              birthPlace(state.birthPlace) &&
              !invalidFields.includes('birthPlace')
            }
            required
          />
          {/* BIRTH DATE */}
          <DatePicker
            disabled={!state.agreed}
            lang={lang}
            accountForm={accountForm}
            accountFormKey="birthDate"
            onChange={birthDate =>
              dispatch({ type: actions.BIRTH_DATE, data: birthDate })
            }
            value={state.birthDate}
            valid={
              !invalidFields.includes('birthDate') &&
              validateDate({
                current: state.birthDate,
                min: yearsAgo(130),
                max: yearsAgo(14),
              })
            }
            max={isoDate(yearsAgo(14))}
            required
          />
          {/* CITIZENSHIP */}
          <CountriesInput
            disabled={!state.agreed}
            lang={lang}
            initialValue={initialState.citizenship}
            allCountries={citizenshipCountriesList}
            content={accountForm.citizenshipInput}
            onChange={id => dispatch({ type: actions.CITIZENSHIP, data: id })}
            valid={!invalidFields.includes('citizenship')}
            required
          />
        </div>

        <div className={styles.rightCol}>
          {/* PASSPORT */}
          <div className={styles.passportToggleContainer}>
            <h2 className={styles.passportTitle}>
              {accountForm.passportTitle[lang]}
            </h2>
            {/* IS RUSSIAN PASSPORT */}
            <PassportType
              lang={lang}
              accountForm={accountForm}
              onChange={data => {
                dispatch({
                  type: actions.PASSPORT_TYPE,
                  data,
                })
                const isRussianNow = data === RUSSIAN_PASSPORT
                /* PASSPORT SIDE EFFECT */
                // If passport number or series was entered before type change
                // was set to false make sure that is will be valid
                dispatch({
                  type: actions.PASSPORT_NUMBER,
                  data: passportInputHandler({
                    newValue: state.passportNumber,
                    oldValue: state.passportNumber,
                    isRussianPassport: isRussianNow,
                    kind: 'id',
                  }),
                })
                dispatch({
                  type: actions.PASSPORT_AUTHORITY_CODE,
                  data: passportInputHandler({
                    newValue: state.passportAuthorityCode,
                    oldValue: state.passportAuthorityCode,
                    isRussianPassport: isRussianNow,
                    kind: 'authority',
                  }),
                })
                dispatch({
                  type: actions.PASSPORT_SERIES,
                  data: isRussianNow
                    ? state.passportSeries.replace(/\D/gim, '').substr(-4)
                    : state.passportSeries,
                })
              }}
              value={state.passportType}
              valid={
                !invalidFields.includes('passportType') &&
                validateString(state.passportType, 300, true)
              }
              required
            />
          </div>

          {/* PASSPORT SERIES */}
          {lang === 'rus' && (
            <TextInput
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.passportSeries}
              onChange={passportSeries => {
                dispatch({
                  type: actions.PASSPORT_SERIES,
                  data:
                    state.passportType === RUSSIAN_PASSPORT
                      ? passportSeries.replace(/\D/gim, '').substr(0, 4)
                      : passportSeries,
                })
                // Switch focus to passportNumber input for russian passport
                // if current input filled as expected
                if (
                  state.passportType === RUSSIAN_PASSPORT &&
                  passportSeries.length === 4
                ) {
                  setFocus('passportNumber')
                }
              }}
              value={state.passportSeries || ''}
              required={state.passportType === RUSSIAN_PASSPORT}
              valid={
                passportDigits(state.passportSeries) &&
                !invalidFields.includes('passportSeries')
              }
              onBlur={() => setFocus('')}
              focus={focus === 'passportSeries' || undefined}
            />
          )}

          {/* PASSPORT NUMBER */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.passportNumber}
            onChange={passportNumber => {
              const number = passportInputHandler({
                newValue: passportNumber,
                oldValue: state.passportNumber,
                isRussianPassport: state.passportType === RUSSIAN_PASSPORT,
                kind: 'id',
              })
              dispatch({
                type: actions.PASSPORT_NUMBER,
                data: number,
              })
              // Change focus for valid value for Russian passport
              if (
                state.passportType === RUSSIAN_PASSPORT &&
                number.length === 7
              ) {
                setFocus('passportAuthorityCode')
              }
            }}
            value={state.passportNumber}
            required
            valid={
              passportDigits(state.passportNumber) &&
              !invalidFields.includes('passportNumber')
            }
            onBlur={() => setFocus('')}
            focus={focus === 'passportNumber' || undefined}
          />

          {/* PASSPORT AUTHORITY CODE */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.passportAuthorityCode}
            onChange={passportAuthorityCode => {
              const authorityCode = passportInputHandler({
                newValue: passportAuthorityCode,
                oldValue: state.passportAuthorityCode,
                isRussianPassport: state.passportType === RUSSIAN_PASSPORT,
                kind: 'authority',
              })
              dispatch({
                type: actions.PASSPORT_AUTHORITY_CODE,
                data: authorityCode,
              })
              // Switch focus if is russian passport an value is valid
              if (
                state.passportType === RUSSIAN_PASSPORT &&
                authorityCode.length === 7
              ) {
                setFocus('passportIAt')
              }
            }}
            value={state.passportAuthorityCode}
            required={state.passportType === RUSSIAN_PASSPORT}
            valid={
              passportDigits(state.passportAuthorityCode) &&
              !invalidFields.includes('passportAuthorityCode')
            }
            onBlur={() => setFocus('')}
            focus={focus === 'passportAuthorityCode' || undefined}
          />

          {/* PASSPORT ISSUE DATE */}
          <DatePicker
            disabled={!state.agreed}
            lang={lang}
            accountForm={accountForm}
            accountFormKey="passportIAt"
            onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
            value={state.passportIAt}
            required
            valid={
              !invalidFields.includes('passportIAt') &&
              validateDate({
                current: state.passportIAt,
                min: yearsAgo(130),
                max: new Date(),
              })
            }
            onBlur={() => setFocus('')}
            focus={focus === 'passportIAt' || undefined}
          />

          {/* PASSPORT ISSUED BY */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.passportIssuedBy}
            onChange={passportIssuedBy =>
              state.passportType === RUSSIAN_PASSPORT
                ? dispatch({
                    type: actions.PASSPORT_ISSUED_BY,
                    data: passportIssuedBy.toLocaleUpperCase(),
                  })
                : dispatch({
                    type: actions.PASSPORT_ISSUED_BY,
                    data: passportIssuedBy,
                  })
            }
            value={state.passportIssuedBy}
            required
            valid={
              passportText(state.passportIssuedBy) &&
              !invalidFields.includes('passportIssuedBy')
            }
            onBlur={() => setFocus('')}
            focus={focus === 'passportIssuedBy' || undefined}
          />
        </div>
      </main>
      {invalidFields.length > 0 && (
        <div className={styles.validationError}>
          <ErrorMessage message={accountForm.validationError[lang]} />
        </div>
      )}
    </>
  )
}

export default ScreenOne
