import React, { useMemo } from 'react'
import Creatable from 'react-select/creatable'
import { ValueType, ActionMeta } from 'react-select/src/types'

import SelectInputDropdownButton from './SelectInputDropdownButton'
import { Option, Lang } from '../common-types'
import { generateId } from '../helpers'
import AccountForm from '../content/account-form.types'
import reactSelectStyles, { theme } from '../utils/react-select-styles'

import styles from './PassportType.module.scss'

interface Props {
  lang: Lang
  onChange: (value: string) => void
  accountForm: AccountForm
  valid: boolean
  value: string
  required?: boolean
}

function PassportType({
  lang,
  accountForm,
  onChange,
  valid,
  value,
  required,
}: Props) {
  const id = useMemo(generateId, [])
  const optionsList = useMemo(
    () =>
      accountForm.passportTypeValues.map(option => ({
        value: option[lang],
        label: option[lang],
      })),
    [accountForm.passportTypeValues, lang]
  )

  function handleChange(
    option: ValueType<Option>,
    { action }: ActionMeta
  ): void {
    if (option === null) {
      return onChange('')
    }
    const { value } = option as Option
    return onChange(value)
  }

  return (
    <label htmlFor={id} className={styles.label}>
      <p className={styles.labelText}>
        {accountForm.passportTypeInput.label[lang]}
        {required && <span className={styles.required}>&nbsp;*</span>}
      </p>
      <Creatable
        inputId={id}
        isClearable
        options={optionsList}
        value={{
          label: value,
          value,
        }}
        onChange={handleChange}
        blurInputOnSelect
        captureMenuScroll
        className={`${(!valid && styles.invalid) || ''} ${
          styles.inputContainer
        }`.trim()}
        classNamePrefix={styles.inputItem}
        placeholder={accountForm.passportTypeInput.placeholder[lang]}
        noOptionsMessage={() => accountForm.noOptionsMessage[lang]}
        formatCreateLabel={value =>
          `${accountForm.createOption[lang]}: ${value}`
        }
        theme={theme}
        styles={reactSelectStyles}
        components={{ DropdownIndicator: SelectInputDropdownButton }}
      />
    </label>
  )
}

export default PassportType
