import React from 'react'

import styles from './CheckBox.module.scss'

interface Props {
  checked?: boolean
  highlight?: boolean
  className?: string
}

export default function CheckBox({ checked, highlight, className }: Props) {
  const containerClassName = highlight
    ? `${styles.container} ${styles.highlight} ${className}`.trim()
    : `${styles.container} ${className}`.trim()
  return (
    <span aria-hidden="true" className={containerClassName}>
      {checked && <span className={styles.checked} />}
    </span>
  )
}
