import React, { useMemo } from 'react'
import Creatable from 'react-select/creatable'
import { ValueType, ActionMeta } from 'react-select/src/types'

import reactSelectStyles, { theme } from '../utils/react-select-styles'
import { Option, Lang } from '../common-types'
import { generateId } from '../helpers'

import styles from './ReferencesInput.module.scss'
import AccountForm from '../content/account-form.types'

// TODO: set up fonts. Current is too big ang probably is from wrong family

interface Props {
  lang: Lang
  onChange: (value: string[]) => void
  initialValue: string[]
  accountForm: AccountForm
  valid: boolean
  required?: boolean
}

export default function ReferencesInput({
  lang,
  accountForm,
  onChange,
  initialValue,
  valid,
  required,
}: Props) {
  const id = useMemo(generateId, [])
  const optionsList = useMemo(
    () =>
      accountForm.referencesInputValues.map(option => ({
        value: option[lang],
        label: option[lang],
      })),
    [accountForm.referencesInputValues, lang]
  )
  const defaultValue = useMemo(
    () =>
      initialValue.map(option => ({
        value: option,
        label: option,
      })),
    [initialValue]
  )

  function handleChange(
    option: ValueType<Option>,
    { action }: ActionMeta
  ): void {
    if (option === null) {
      return onChange([])
    }
    if (Array.isArray(option)) {
      return onChange(option.map(i => i.value || ''))
    }
    console.error('Invalid value in multiselect input:', action, option)
  }
  return (
    <label htmlFor={id} className={styles.label}>
      <p className={styles.labelText}>
        {accountForm.referencesInputText.label[lang]}
        {required && <span className={styles.required}>&nbsp;*</span>}
      </p>
      <Creatable
        inputId={id}
        isClearable
        isMulti
        options={optionsList}
        defaultValue={defaultValue}
        onChange={handleChange}
        blurInputOnSelect
        captureMenuScroll
        className={`${(!valid && styles.invalid) || ''} ${
          styles.inputContainer
        }`.trim()}
        classNamePrefix={styles.inputItem}
        placeholder={accountForm.referencesInputText.placeholder[lang]}
        noOptionsMessage={() => accountForm.referencesNoOptions[lang]}
        formatCreateLabel={value =>
          `${accountForm.referencesCreateOption[lang]}: ${value}`
        }
        theme={theme}
        styles={reactSelectStyles}
      />
    </label>
  )
}
