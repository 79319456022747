import React from 'react'

import styles from './SummaryItem.module.scss'

interface Props {
  value: string | string[]
  label: string
}

export default function SummaryItem({ value, label }: Props) {
  if (Array.isArray(value)) {
    value = value.filter(Boolean).join(', ')
  }
  if (!value) {
    return null
  }
  if (value.length > 0) {
    return (
      <p className={styles.paragraph}>
        <b>{`${label}:`}</b>&nbsp;{value}
      </p>
    )
  }
  return null
}
