import React from 'react'

import styles from './LoadingScreen.module.scss'

const LoadingScreen: React.FC = ({ children }) => (
  <div className={styles.container}>
    <h3 className={styles.header}>{children}</h3>
    <div className={styles.spinner}>
      <div className={styles.leftTop} />
      <div className={styles.rigthTop} />
      <div className={styles.leftBottom} />
      <div className={styles.rigthBottom} />
    </div>
  </div>
)

export default LoadingScreen
