import React, { useMemo } from 'react'

import { ScreenProps, Country, ContentInput } from '../common-types'
import { actions } from '../state-actions-reducers'
import { validateReferences } from '../validators'
import RecommendationsInput from './RecommendationsInput'
import ReferencesInput from './ReferencesInput'
import AdditionalLinks from './MultiTextInput'
// import SocialLinksInput from './SocialLinksInput'

import styles from './ScreenFive.module.scss'
import ErrorMessage from './ErrorMessage'

interface Props extends ScreenProps {
  allCountries: Country[]
}

export default function ScreenFive({
  lang,
  state,
  dispatch,
  accountForm,
  allCountries,
  invalidFields,
}: Props) {
  const countries = useMemo(
    () =>
      allCountries.reduce(
        (object, country) => ({
          ...object,
          [country.alpha2]: country[lang],
        }),
        {}
      ),
    [allCountries, lang]
  )
  const socialLinksInputs: [string, symbol][] = [
    ['vk', actions.VK],
    ['facebook', actions.FACEBOOK],
    ['youtube', actions.YOUTUBE],
    ['vimeo', actions.VIMEO],
    ['instagram', actions.INSTAGRAM],
    ['workWebsite', actions.WORK_WEBSITE],
    ['personalWebsite', actions.PERSONAL_WEBSITE],
    ['additionalLinks', actions.ADDITIONAL_LINKS],
  ]
  return (
    <>
      <main className={styles.main}>
        <RecommendationsInput
          lang={lang}
          recommendationsLabel={accountForm.recommendationsLabel}
          recommendationsName={accountForm.recommendationsName}
          recommendationsPhone={accountForm.recommendationsPhone}
          recommendations={state.recommendations}
          setRecommendations={data =>
            dispatch({
              type: actions.RECOMMENDATIONS,
              data,
            })
          }
          deleteButton={accountForm.removeFieldButton}
          addFieldButton={accountForm.addFieldButton}
          countries={countries}
        />
        <ReferencesInput
          lang={lang}
          accountForm={accountForm}
          initialValue={state.references}
          onChange={data =>
            dispatch({
              type: actions.REFERENCES,
              data,
            })
          }
          valid={
            validateReferences(state.references) &&
            !invalidFields.includes('references')
          }
          required
        />
        {socialLinksInputs.map(([fieldKey, actionType], index) => (
          <AdditionalLinks
            key={index}
            lang={lang}
            values={state[fieldKey] as string[]}
            content={accountForm[fieldKey] as ContentInput}
            addField={accountForm.addFieldButton}
            removeField={accountForm.removeFieldButton}
            onChange={data => dispatch({ type: actionType, data })}
          />
        ))}
        <p className={styles.additionalInfoNote}>
          {accountForm.additionalInfoNote[lang]}
        </p>
      </main>
      {invalidFields.length > 0 && (
        <div className={styles.validationError}>
          <ErrorMessage message={accountForm.validationError[lang]} />
        </div>
      )}
    </>
  )
}
