import React, { useState, useEffect } from 'react'

import { Lang } from '../common-types'
import { Dispatch, actions, State } from '../state-actions-reducers'
import AccountForm from '../content/account-form.types'
import UppyDashboard from './UppyDashboard'
import { usePortfolioUppy } from '../uppyCustomHooks'
import { saveUppyState, UPPY_PORTFOLIO_IMAGES } from '../local-storage'

import styles from './ScreenSix.module.scss'

interface Props {
  lang: Lang
  dispatch: Dispatch
  accountForm: AccountForm
  state: State
}

/* THE COMPONENT */
function ScreenSix({ lang, dispatch, accountForm, state }: Props) {
  const uppy = usePortfolioUppy(
    lang,
    state.formId,
    dispatch,
    actions.PORTFOLIO_IMAGE
  )

  const [emptyUppy, setEmptyUppy] = useState(state.portfolioImages.length === 0)
  useEffect(() => {
    setEmptyUppy(state.portfolioImages.length === 0)
  }, [state.portfolioImages.length])

  useEffect(() => {
    if (emptyUppy) {
      const removeBorders = () => setEmptyUppy(false)
      uppy.on('file-added', removeBorders)
      return () => {
        uppy.off('file-added', removeBorders)
      }
    } else {
      const addBorders = () => setEmptyUppy(true)
      uppy.on('file-removed', addBorders)
      return () => {
        uppy.off('file-removed', addBorders)
      }
    }
  }, [emptyUppy, uppy])
  const containerClass = emptyUppy
    ? styles.uploadContainerBorderFull
    : styles.uploadContainerBorderLess
  const wrapperClass = emptyUppy
    ? styles.wrapperBorderFull
    : styles.wrapperBorderLess

  const header = accountForm.uploadPortfolioHeader[lang]
  const description = accountForm.uploadPortfolioText[lang]
  return (
    <main className={styles.main}>
      <h2 className={styles.header}>{header}</h2>
      <p className={styles.description}>{description}</p>
      <div className={containerClass}>
        <div className={wrapperClass}>
          <UppyDashboard lang={lang} uppy={uppy} />
        </div>
      </div>
      <button
        className={styles.resetButton}
        type="button"
        onClick={() => {
          // First - reset uppy
          uppy.reset()
          // Then save new state to the localStorage
          saveUppyState(UPPY_PORTFOLIO_IMAGES, uppy.getState())
          // Then update app's state
          dispatch({
            type: actions.PORTFOLIO_IMAGE_RESET,
            // When reset, data prop is not used. We pass it here just to
            // stop typescript yelling errors.
            data: false,
          })
        }}
      >
        {accountForm.uploadDeleteButton[lang]}
      </button>
    </main>
  )
}

export default ScreenSix
