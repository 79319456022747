import React, { useState, useEffect } from 'react'

import { useDefer } from '../helpers'
import CloseButton from './CloseButton'
import PlusSignIcon from './PlusSignIcon'

import 'react-phone-input-2/dist/style.css'

import { validateEmail } from '../validators'
import AccountForm from '../content/account-form.types'
import { Lang } from '../common-types'

import styles from './EmailInput.module.scss'

interface Props {
  emails: string[]
  setEmails: (s: string[]) => void
  accountForm: AccountForm
  lang: Lang
  valid: boolean
  required?: boolean
}

export default function EmailInput({
  emails,
  setEmails,
  accountForm,
  lang,
  valid,
  required,
}: Props) {
  // There is always must be at least one input field
  const [state, setState] = useState(emails.length === 0 ? [''] : emails)

  // Update upper state after some time on changes.
  // We manualy update state when delete field (see delete button onClick handler)
  const [inOnChangeInput, setInOnChangeInput] = useState(false)
  useDefer(
    () => {
      if (inOnChangeInput) {
        setInOnChangeInput(false)
        setEmails(state)
      }
    },
    [state],
    300
  )
  const [deleting, setDeleting] = useState(false)
  const addFieldBtnClassName = deleting
    ? `${styles.animateDeleting} ${styles.addField}`
    : styles.addField

  const [lastInput, setLastInput] = useState(state.length - 1)
  useEffect(() => {
    setLastInput(state.length - 1)
  }, [state.length])

  return (
    <section className={styles.container}>
      <label className={styles.label} htmlFor={`email-${lastInput}`}>
        <p className={styles.labelText}>
          {accountForm.email.label[lang]}
          {required && <span className={styles.required}>&nbsp;*</span>}
        </p>
      </label>
      <ul className={styles.list}>
        {state.map((email, index) => (
          <li key={index} className={styles.listItem}>
            <div
              className={`${(!valid || !validateEmail(email)) &&
                styles.invalid} ${styles.inputAndButtonWrapper}`.trim()}
            >
              <input
                id={`email-${index}`}
                type="email"
                placeholder={accountForm.email.placeholder[lang]}
                value={email}
                onChange={({ target }) => {
                  const { value } = target
                  // Set state with new array of emails.
                  // New array is a copy of previous one with all indices kept
                  // only current value changed under current index.
                  // It is same as we set array[index] = newValue but instead
                  // mutating old array we get new one.
                  // Get parts of an array without current item
                  const [firsPart, lastPart] = [
                    // part before current item
                    state.slice(0, index),
                    // part after current item
                    state.slice(index + 1),
                  ]
                  setState([...firsPart, value, ...lastPart])
                  setInOnChangeInput(true)
                }}
                className={styles.input}
              />
              <button
                aria-label={accountForm.removeFieldButton[lang]}
                className={styles.deleteButton}
                type="button"
                onClick={() => {
                  // Get parts of an array without current item
                  const [firsPart, lastPart] = [
                    // part before current item
                    state.slice(0, index),
                    // part after current item
                    state.slice(index + 1),
                  ]
                  const newState = [...firsPart, ...lastPart]
                  setEmails(newState)
                  setState(newState)
                  // Animate deleting
                  setDeleting(true)
                  setTimeout(() => setDeleting(false), 300)
                }}
                disabled={state.length === 1}
              >
                <CloseButton />
              </button>
            </div>
          </li>
        ))}
      </ul>
      <button
        title={accountForm.addFieldButton[lang]}
        aria-label={accountForm.addFieldButton[lang]}
        disabled={state.length === 12}
        type="button"
        className={addFieldBtnClassName}
        onClick={() => setState([...state, ''])}
      >
        <PlusSignIcon />
      </button>
    </section>
  )
}
