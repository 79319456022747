import React from 'react'

import SummaryItem from './SummaryItem'
import AccountForm from '../content/account-form.types'
import { Lang, ContentInput } from '../common-types'
import { State } from '../state-actions-reducers'

interface Props {
  fieldNames: [string, string][] // [[label, value], [label, value]]
  accountForm: AccountForm
  lang: Lang
  state: State
}

export default function MultipleSummary({
  fieldNames,
  accountForm,
  lang,
  state,
}: Props) {
  return (
    <>
      {fieldNames.map(item => {
        const [accounFormKey, stateKey] = item
        const contentOfInput = accountForm[accounFormKey] as ContentInput
        const labelText = contentOfInput.label[lang]
        const value = state[stateKey] as string
        return <SummaryItem key={labelText} label={labelText} value={value} />
      })}
    </>
  )
}
