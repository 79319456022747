import { useEffect } from 'react'
import nanoid from 'nanoid'

import {
  getSavedUserId,
  getSavedFormId,
  saveUserId,
  saveFormId,
} from './local-storage'
import { request } from './helpers'
import { IdKind } from './common-types'
import { TOKEN_ENDPOINT } from './rentaphoto-server-parameters'

// Reques userId or formId from server
// Must provide saved id (as token) if
// it exists. For formId must provide userId
export async function getId(
  kind: IdKind,
  token: string | null,
  userId?: string
): Promise<string> {
  const id = await request(
    TOKEN_ENDPOINT,
    {
      kind,
      userId: userId,
      token,
    },
    'Error while token generation'
  )
  if (!id) {
    return nanoid(64)
  }
  return id
}

interface UseTokenCallback {
  ({ userId, formId }: { userId: string; formId: string }): void
}

// Fetch api to get userId and formId.
// Expected callback funtion with will recieve
export async function getUserAndFormId(
  callback: UseTokenCallback,
  localStorage: Storage
) {
  const userId = await getId('userId', getSavedUserId(localStorage))
  const formId = await getId('formId', getSavedFormId(localStorage), userId)
  saveUserId(userId, localStorage)
  saveFormId(formId, localStorage)
  callback({ userId, formId })
}

export default function useToken(
  callback: UseTokenCallback,
  deps: unknown[] = []
) {
  useEffect(() => {
    getUserAndFormId(callback, localStorage)
  }, deps)
}
