import React, { useRef, useState } from 'react'

import { ScreenProps, Country } from '../common-types'
import { actions } from '../state-actions-reducers'
import { validateString } from '../validators'
import { isoDate, yearsAgo } from '../helpers'
import TextInput from './TextInput'
import PhoneInput from './PhoneInput'
import SelectInput from './SelectInput'
import CheckBoxInput from './CheckBoxInput'
import DatePicker from './DatePicker'

import styles from './ScreenFour.module.scss'
import ErrorMessage from './ErrorMessage'
import EmailInput from './EmailInput'

interface Props extends ScreenProps {
  allCountries: Country[]
}

export default function ScreenFour({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
  allCountries,
}: Props) {
  const countriesRef = useRef(
    allCountries.reduce(
      (object, country) => ({
        ...object,
        [country.alpha2]: country[lang],
      }),
      {}
    )
  )
  const countries = countriesRef.current
  const [focus, setFocus] = useState('')
  return (
    <>
      <main className={styles.main}>
        <div className={styles.leftCol}>
          {/* EMAIL */}
          <EmailInput
            emails={state.emails}
            setEmails={data => {
              dispatch({
                type: actions.EMAILS,
                data,
              })
            }}
            accountForm={accountForm}
            lang={lang}
            valid={!invalidFields.includes('emails')}
            required
          />
          {/* MOBILE PHONE NUMBER */}
          <PhoneInput
            label={accountForm.mobilePhoneNumber.label[lang]}
            numbers={state.mobilePhoneNumbers}
            setNumbers={data =>
              dispatch({
                type: actions.MOBILE_PHONE_NUMBERS,
                data,
              })
            }
            deleteText={accountForm.removeFieldButton[lang]}
            addField={accountForm.addFieldButton[lang]}
            countries={countries}
            valid={!invalidFields.includes('mobilePhoneNumbers')}
            required
          />
          {/* REGISTERED ADDRESS PHONE NUMBER */}
          <PhoneInput
            label={accountForm.addressRegisteredPhone.label[lang]}
            numbers={state.addressRegisteredPhones}
            setNumbers={data =>
              dispatch({
                type: actions.ADDRESS_REGISTERED_PHONES,
                data,
              })
            }
            deleteText={accountForm.removeFieldButton[lang]}
            addField={accountForm.addFieldButton[lang]}
            countries={countries}
            valid={!invalidFields.includes('addressRegisteredPhones')}
          />
          {/* FACTUAL ADDRESS PHONE NUMBER */}
          <PhoneInput
            label={accountForm.addressFactualPhone.label[lang]}
            numbers={state.addressFactualPhones}
            setNumbers={data =>
              dispatch({
                type: actions.ADDRESS_FACTUAL_PHONES,
                data,
              })
            }
            deleteText={accountForm.removeFieldButton[lang]}
            addField={accountForm.addFieldButton[lang]}
            countries={countries}
            valid={!invalidFields.includes('addressFactualPhones')}
          />
          {/* WORK PHONE NUMBER */}
          {/* <div className={styles.workPhoneInputWrapper}> */}
          <PhoneInput
            label={accountForm.workPhoneNumber.label[lang]}
            numbers={state.workPhoneNumbers}
            setNumbers={data =>
              dispatch({
                type: actions.WORK_PHONE_NUMBERS,
                data,
              })
            }
            deleteText={accountForm.removeFieldButton[lang]}
            addField={accountForm.addFieldButton[lang]}
            countries={countries}
            valid={!invalidFields.includes('workPhoneNumbers')}
          />
          {/* </div> */}
        </div>
        <div className={styles.rightCol}>
          {/* WORK PLACE */}
          <TextInput
            lang={lang}
            content={accountForm.occupationInstitution}
            value={state.occupationInstitution}
            onChange={data =>
              dispatch({
                type: actions.OCCUPATION_INSTITUTION,
                data,
              })
            }
            valid={
              !invalidFields.includes('occupationInstitution') &&
              validateString(state.occupationInstitution, 200)
            }
          />
          {/* WORK POSITION */}
          <TextInput
            lang={lang}
            content={accountForm.occupationPosition}
            value={state.occupationPosition}
            onChange={data =>
              dispatch({
                type: actions.OCCUPATION_POSITION,
                data,
              })
            }
            valid={
              !invalidFields.includes('occupationPosition') &&
              validateString(state.occupationPosition, 200)
            }
          />
          {/* MONTHLY INCOME */}
          <SelectInput
            lang={lang}
            noOptionsMessage={accountForm.noOptionsMessage}
            content={accountForm.monthlyIncomeInputText}
            onChange={data =>
              dispatch({
                type: actions.MONTHLY_INCOME,
                data,
              })
            }
            value={state.monthlyIncome}
            options={accountForm.monthlyIncomeInputValues}
            valid={
              !invalidFields.includes('monthlyIncome') &&
              validateString(state.monthlyIncome, 100, false)
            }
          />

          {/* IS STUDENT */}
          <CheckBoxInput
            lang={lang}
            content={accountForm.isStudent}
            onChange={data =>
              dispatch({
                type: actions.IS_STUDENT,
                data,
              })
            }
            value={state.isStudent}
          />
          {/* STUDENT RELATED INPUTS */}
          {state.isStudent && (
            <>
              {/* COLLEGE NAME */}
              <TextInput
                lang={lang}
                content={accountForm.collegeName}
                value={state.collegeName}
                onChange={data =>
                  dispatch({
                    type: actions.COLLEGE_NAME,
                    data,
                  })
                }
                valid={
                  !invalidFields.includes('collegeName') &&
                  validateString(state.collegeName, 300)
                }
              />
              <TextInput
                lang={lang}
                content={accountForm.collegeProfession}
                value={state.collegeProfession}
                onChange={data =>
                  dispatch({
                    type: actions.COLLEGE_PROFESSION,
                    data,
                  })
                }
                valid={
                  !invalidFields.includes('collegeProfession') &&
                  validateString(state.collegeProfession, 300)
                }
              />
              <DatePicker
                lang={lang}
                accountForm={accountForm}
                accountFormKey="collegeStartedAt"
                onChange={data => {
                  dispatch({
                    type: actions.COLLEGE_STARTED_AT,
                    data,
                  })
                }}
                value={state.collegeStartedAt}
                valid={!invalidFields.includes('collegeStartedAt')}
                max={isoDate(yearsAgo(-1))}
                focus={focus === 'collegeStartedAt' || undefined}
                onBlur={() => setFocus('')}
              />
              <DatePicker
                lang={lang}
                accountForm={accountForm}
                accountFormKey="collegeEndedAt"
                onChange={data => {
                  dispatch({
                    type: actions.COLLEGE_ENDED_AT,
                    data,
                  })
                }}
                value={state.collegeEndedAt}
                valid={!invalidFields.includes('collegeEndedAt')}
                max={isoDate(yearsAgo(-12))}
                focus={focus === 'collegeEndedAt' || undefined}
                onBlur={() => setFocus('')}
              />
            </>
          )}
        </div>
      </main>
      {invalidFields.length > 0 && (
        <div className={styles.validationError}>
          <ErrorMessage message={accountForm.validationError[lang]} />
        </div>
      )}
    </>
  )
}
