import React, { useState, useRef } from 'react'

import CloseButton from './CloseButton'
import PlusSignIcon from './PlusSignIcon'
import { BaseInputProps, L10n } from '../common-types'
import { validateUrl } from '../validators'
import { useDefer, generateId } from '../helpers'

import styles from './MultiTextInput.module.scss'

interface Props extends BaseInputProps {
  values: string[]
  onChange: (s: string[]) => void
  addField: L10n
  removeField: L10n
}

export default function MultiTextInput({
  lang,
  values,
  onChange,
  content,
  addField,
  removeField,
}: Props) {
  // Use internal state to avoid unnecessary upper state updates
  const [internalState, setInternalState] = useState(
    values.length > 0 ? values : ['']
  )

  useDefer(() => {
    onChange(internalState)
  }, [internalState])

  const idRef = useRef(generateId(16))

  return (
    <section>
      <ul className={styles.list}>
        {internalState.map((item, index) => (
          <li key={index} className={styles.listItem}>
            <label
              className={styles.label}
              htmlFor={`${idRef.current}-${index}`}
            >
              <p className={styles.labelText}>
                {`${content.label[lang]}${
                  internalState.length > 1 ? ` ${index + 1}` : ''
                }`}
              </p>
              <div
                className={
                  validateUrl(item)
                    ? styles.inputAndButtonWrapper
                    : `${styles.invalid} ${styles.inputAndButtonWrapper}`
                }
              >
                <input
                  id={`${idRef.current}-${index}`}
                  type="text"
                  placeholder={content.placeholder[lang]}
                  className={styles.input}
                  value={item}
                  onChange={e => {
                    // Set state with new array of numbers.
                    // New array is a copy of previous one with all indices kept
                    // only current value changed under current index.
                    // It is same as we set array[index] = newValue but instead
                    // mutating old array we get new one.
                    // Get parts of an array without current item
                    const { value } = e.target
                    const [firsPart, lastPart] = [
                      // part before current item
                      internalState.slice(0, index),
                      // part after current item
                      internalState.slice(index + 1),
                    ]
                    setInternalState([...firsPart, value, ...lastPart])
                  }}
                  maxLength={200}
                />
                <button
                  title={removeField[lang]}
                  aria-label={removeField[lang]}
                  className={styles.deleteButton}
                  type="button"
                  onClick={() => {
                    // Just remove values if no more values left
                    if (internalState.length === 1) {
                      return setInternalState([''])
                    }
                    const [firsPart, lastPart] = [
                      // part before current item
                      internalState.slice(0, index),
                      // part after current item
                      internalState.slice(index + 1),
                    ]
                    setInternalState([...firsPart, ...lastPart])
                  }}
                >
                  <CloseButton />
                </button>
              </div>
            </label>
          </li>
        ))}
      </ul>
      <button
        title={addField[lang]}
        aria-label={addField[lang]}
        type="button"
        className={styles.addField}
        onClick={() => setInternalState([...internalState, ''])}
      >
        <PlusSignIcon />
      </button>
    </section>
  )
}
