import React from 'react'

import TextInput from './TextInput'
import CountriesInput from './CountriesInput'
import { actions } from '../state-actions-reducers'
import { ScreenProps, Country } from '../common-types'
import { validateString } from '../validators'

import styles from './ScreenTwo.module.scss'
import ErrorMessage from './ErrorMessage'
import DatePicker from './DatePicker'

interface Props extends ScreenProps {
  countriesList: Country[]
}

/* THE COMPONENT */
function ScreenTwo({
  lang,
  state,
  initialState,
  dispatch,
  accountForm,
  invalidFields,
  countriesList,
}: Props) {
  return (
    <>
      <main className={styles.main}>
        <div className={styles.leftColHeaderContainer}>
          <h2 className={styles.leftColHeader}>
            {accountForm.addressRegisteredTitle[lang]}
          </h2>
          <button
            className={styles.copyButton}
            type="button"
            onClick={() =>
              dispatch({
                type: actions.ADDRESS_COPY_FROM_FACTUAL,
                data: false,
              })
            }
          >
            {accountForm.addressRegisteredCopyBtnDesc[lang]}
          </button>
        </div>
        <div className={styles.leftCol}>
          {/* ADDRESSES */}
          {/* REGISTRATION ADDRESS COUNTRY */}
          <CountriesInput
            disabled={!state.agreed}
            lang={lang}
            initialValue={initialState.addressRegisteredCountry}
            allCountries={countriesList}
            content={accountForm.addressCountry}
            onChange={id =>
              dispatch({ type: actions.ADDRESS_REGISTERED_COUNTRY, data: id })
            }
            valid={
              !invalidFields.includes('addressRegisteredCountry') &&
              validateString(state.addressRegisteredCountry, 3, false)
            }
            value={state.addressRegisteredCountry}
          />

          {/* ADDRESSES */}
          {/* REGISTRATION ADDRESS REGION */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressRegion}
            onChange={addressRegisteredRegion => {
              dispatch({
                type: actions.ADDRESS_REGISTERED_REGION,
                data: addressRegisteredRegion,
              })
            }}
            valid={
              validateString(state.addressRegisteredRegion, 200) &&
              !invalidFields.includes('addressRegisteredRegion')
            }
            value={state.addressRegisteredRegion}
          />

          {/* ADDRESSES */}
          {/* REGISTRATION ADDRESS CITY */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressCity}
            onChange={addressRegisteredCity => {
              dispatch({
                type: actions.ADDRESS_REGISTERED_CITY,
                data: addressRegisteredCity,
              })
            }}
            valid={
              validateString(state.addressRegisteredCity, 200) &&
              !invalidFields.includes('addressRegisteredCity')
            }
            value={state.addressRegisteredCity}
          />

          {/* ADDRESSES */}
          {/* REGISTRATION ADDRESS STREET */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressStreet}
            onChange={addressRegisteredStreet => {
              dispatch({
                type: actions.ADDRESS_REGISTERED_STREET,
                data: addressRegisteredStreet,
              })
            }}
            valid={
              validateString(state.addressRegisteredStreet, 200) &&
              !invalidFields.includes('addressRegisteredStreet')
            }
            value={state.addressRegisteredStreet}
          />

          {/* ADDRESSES */}
          {/* REGISTRATION ADDRESS BUILDING */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressBuilding}
            onChange={addressRegisteredBuilding => {
              dispatch({
                type: actions.ADDRESS_REGISTERED_BUILDING,
                data: addressRegisteredBuilding,
              })
            }}
            valid={
              validateString(state.addressRegisteredBuilding, 100) &&
              !invalidFields.includes('addressRegisteredBuilding')
            }
            value={state.addressRegisteredBuilding}
          />

          {/* ADDRESSES */}
          {/* REGISTRATION ADDRESS APARTMENTS */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressApartments}
            onChange={addressRegisteredApartments => {
              dispatch({
                type: actions.ADDRESS_REGISTERED_APARTMENTS,
                data: addressRegisteredApartments,
              })
            }}
            valid={
              validateString(state.addressRegisteredApartments, 50) &&
              !invalidFields.includes('addressRegisteredApartments')
            }
            value={state.addressRegisteredApartments}
          />
          <DatePicker
            lang={lang}
            accountForm={accountForm}
            accountFormKey="addressRegisteredDate"
            onChange={data =>
              dispatch({
                type: actions.ADDRESS_REGISTERED_DATE,
                data,
              })
            }
            value={state.addressRegisteredDate}
            valid={!invalidFields.includes('addressRegisteredDate')}
          />
        </div>

        {/* FACTUAL ADDRESS */}
        <div className={styles.rightColHeaderContainer}>
          <h2 className={styles.rightColHeader}>
            {accountForm.addressFactualTitle[lang]}
          </h2>
          <button
            className={styles.copyButton}
            type="button"
            onClick={() =>
              dispatch({
                type: actions.ADDRESS_COPY_FROM_REGISTERED,
                data: false,
              })
            }
          >
            {accountForm.addressFactualCopyBtnDesc[lang]}
          </button>
        </div>
        <div className={styles.rightCol}>
          {/* ADDRESSES */}
          {/* FACTUAL ADDRESS COUNTRY */}
          <CountriesInput
            disabled={!state.agreed}
            lang={lang}
            initialValue={initialState.addressFactualCountry}
            allCountries={countriesList}
            content={accountForm.addressCountry}
            onChange={id =>
              dispatch({ type: actions.ADDRESS_FACTUAL_COUNTRY, data: id })
            }
            valid={
              !invalidFields.includes('addressFactualCountry') &&
              validateString(state.addressFactualCountry, 3, false)
            }
            value={state.addressFactualCountry}
            required
          />

          {/* ADDRESSES */}
          {/* FACTUAL ADDRESS REGION */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressRegion}
            onChange={addressFactualRegion => {
              dispatch({
                type: actions.ADDRESS_FACTUAL_REGION,
                data: addressFactualRegion,
              })
            }}
            valid={
              validateString(state.addressFactualRegion, 200) &&
              !invalidFields.includes('addressFactualRegion')
            }
            value={state.addressFactualRegion}
          />

          {/* ADDRESSES */}
          {/* FACTUAL ADDRESS CITY */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressCity}
            onChange={addressFactualCity => {
              dispatch({
                type: actions.ADDRESS_FACTUAL_CITY,
                data: addressFactualCity,
              })
            }}
            valid={
              validateString(state.addressFactualCity, 200) &&
              !invalidFields.includes('addressFactualCity')
            }
            value={state.addressFactualCity}
            required
          />

          {/* ADDRESSES */}
          {/* FACTUAL ADDRESS STREET */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressStreet}
            onChange={addressFactualStreet => {
              dispatch({
                type: actions.ADDRESS_FACTUAL_STREET,
                data: addressFactualStreet,
              })
            }}
            valid={
              validateString(state.addressFactualStreet, 200) &&
              !invalidFields.includes('addressFactualStreet')
            }
            value={state.addressFactualStreet}
          />

          {/* ADDRESSES */}
          {/* FACTUAL ADDRESS BUILDING */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressBuilding}
            onChange={addressFactualBuilding => {
              dispatch({
                type: actions.ADDRESS_FACTUAL_BUILDING,
                data: addressFactualBuilding,
              })
            }}
            valid={
              validateString(state.addressFactualBuilding, 100) &&
              !invalidFields.includes('addressFactualBuilding')
            }
            value={state.addressFactualBuilding}
            required
          />

          {/* ADDRESSES */}
          {/* FACTUAL ADDRESS APARTMENTS */}
          <TextInput
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.addressApartments}
            onChange={addressFactualApartments => {
              dispatch({
                type: actions.ADDRESS_FACTUAL_APARTMENTS,
                data: addressFactualApartments,
              })
            }}
            valid={
              validateString(state.addressFactualApartments, 50) &&
              !invalidFields.includes('addressFactualApartments')
            }
            value={state.addressFactualApartments}
          />
        </div>
      </main>
      {invalidFields.length > 0 && (
        <div className={styles.validationError}>
          <ErrorMessage message={accountForm.validationError[lang]} />
        </div>
      )}
    </>
  )
}

export default ScreenTwo
