import React, { useRef, useEffect } from 'react'

import { TextInputProps } from '../common-types'
import { generateId } from '../helpers'

import styles from './TextInput.module.scss'

function TextInput({
  disabled,
  lang,
  content,
  onChange,
  value,
  className,
  valid,
  required,
  focus,
  onBlur,
}: TextInputProps) {
  const idRef = useRef(generateId(16))
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focus])
  return (
    <label
      htmlFor={idRef.current}
      className={`${className || ''} ${styles.label}`.trim()}
    >
      <p className={styles.labelText}>
        {content.label[lang]}
        {required && <span className={styles.required}>&nbsp;*</span>}
      </p>
      <input
        id={idRef.current}
        ref={inputRef}
        disabled={disabled}
        className={`${styles.input} ${valid ? '' : styles.invalid}`}
        type="text"
        placeholder={content.placeholder[lang]}
        onChange={event => {
          if (event.target) {
            onChange(event.target.value as string, event)
          }
        }}
        value={value}
        maxLength={500}
        onBlur={onBlur}
      />
    </label>
  )
}

export default TextInput
