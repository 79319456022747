// Here we define the state and it's validator function

import {
  daysAgo,
  isoDate,
  copyFromFactual,
  copyFromRegistered,
} from './helpers'
import { loadState, resetLocalStorage } from './local-storage'
import { Recommendation } from './common-types'

export const STATE_REVISION = '0.005'
export const RUSSIAN_PASSPORT = 'Паспорт гражданина РФ'

/*
  In order to add new state value we need to do four things:
  1) Add type definition to the State interface
  2) Add initial value to the INITIAL_STATE
  3) Add new action to the actions
  4) Add new case handling to the reducer
*/

// Define state for all inputs
/* STATE INTERFACE */
export interface State {
  [key: string]: string | string[] | boolean | Recommendation[] | number
  currentStep: number
  revision: string
  createdAt: string
  formId: string
  userId: string
  agreed: boolean
  passportType: string
  citizenship: string
  firstName: string
  lastName: string
  patronymic: string
  birthDate: string
  birthPlace: string
  passportIAt: string
  passportSeries: string
  passportNumber: string
  passportIssuedBy: string
  passportAuthorityCode: string
  addressRegisteredDate: string
  addressRegisteredCountry: string
  addressRegisteredRegion: string
  addressRegisteredCity: string
  addressRegisteredStreet: string
  addressRegisteredBuilding: string
  addressRegisteredApartments: string
  addressFactualCountry: string
  addressFactualRegion: string
  addressFactualCity: string
  addressFactualStreet: string
  addressFactualBuilding: string
  addressFactualApartments: string
  passportImages: string[]
  portfolioImages: string[]
  mobilePhoneNumbers: string[]
  addressRegisteredPhones: string[]
  addressFactualPhones: string[]
  workPhoneNumbers: string[]
  emails: string[]
  occupationInstitution: string
  occupationPosition: string
  monthlyIncome: string
  isStudent: boolean
  collegeName: string
  collegeProfession: string
  collegeStartedAt: string
  collegeEndedAt: string
  recommendations: Recommendation[]
  references: string[]
  vk: string[]
  facebook: string[]
  youtube: string[]
  vimeo: string[]
  instagram: string[]
  workWebsite: string[]
  personalWebsite: string[]
  additionalLinks: string[]
  subscribe: boolean
  feedback: string
  agreedOn: string[]
}

export const INITIAL_STATE: State = {
  currentStep: 0,
  createdAt: isoDate(new Date()),
  revision: STATE_REVISION,
  formId: '',
  userId: '',
  agreed: false,
  passportType: RUSSIAN_PASSPORT,
  citizenship: '643', // 643 - is the ISO code for Russia
  firstName: '',
  lastName: '',
  patronymic: '',
  passportSeries: '',
  passportNumber: '',
  passportIAt: '',
  birthDate: '',
  birthPlace: '',
  passportIssuedBy: '',
  passportAuthorityCode: '',
  addressRegisteredDate: '',
  addressRegisteredCountry: '643',
  addressRegisteredRegion: '',
  addressRegisteredCity: '',
  addressRegisteredStreet: '',
  addressRegisteredBuilding: '',
  addressRegisteredApartments: '',
  addressFactualCountry: '643',
  addressFactualRegion: '',
  addressFactualCity: '',
  addressFactualStreet: '',
  addressFactualBuilding: '',
  addressFactualApartments: '',
  passportImages: [],
  portfolioImages: [],
  mobilePhoneNumbers: [],
  addressRegisteredPhones: [],
  addressFactualPhones: [],
  workPhoneNumbers: [],
  emails: [],
  occupationInstitution: '',
  occupationPosition: '',
  monthlyIncome: '',
  isStudent: false,
  collegeName: '',
  collegeProfession: '',
  collegeStartedAt: '',
  collegeEndedAt: '',
  recommendations: [],
  references: [],
  vk: [],
  facebook: [],
  youtube: [],
  vimeo: [],
  instagram: [],
  workWebsite: [],
  personalWebsite: [],
  additionalLinks: [],
  subscribe: false,
  feedback: '',
  agreedOn: [],
}

// Actions
export const actions = {
  CURRENT_STEP: Symbol('CURRENT_STEP'),
  FORM_ID: Symbol('FORM_ID'),
  USER_ID: Symbol('USER_ID'),
  AGREED: Symbol('AGREED'),
  PASSPORT_TYPE: Symbol('PASSPORT_TYPE'),
  CITIZENSHIP: Symbol('CITIZENSHIP'),
  REPLACE: Symbol('REPLACE'),
  RESET: Symbol('RESET'),
  FIRST_NAME: Symbol('FIRST_NAME'),
  LAST_NAME: Symbol('LAST_NAME'),
  PATRONYMIC: Symbol('PATRONYMIC'),
  BIRTH_DATE: Symbol('BIRTH_DATE'),
  BIRTH_PLACE: Symbol('BIRTH_PLACE'),
  PASSPORT_IAT: Symbol('PASSPORT_IAT'),
  PASSPORT_SERIES: Symbol('PASSPORT_SERIES'),
  PASSPORT_NUMBER: Symbol('PASSPORT_NUMBER'),
  PASSPORT_ISSUED_BY: Symbol('PASSPORT_ISSUED_BY'),
  PASSPORT_AUTHORITY_CODE: Symbol('PASSPORT_AUTHORITY_CODE'),
  ADDRESS_REGISTERED_DATE: Symbol('ADDRESS_REGISTERED_DATE'),
  ADDRESS_REGISTERED_COUNTRY: Symbol('ADDRESS_REGISTERED_COUNTRY'),
  ADDRESS_REGISTERED_REGION: Symbol('ADDRESS_REGISTERED_REGION'),
  ADDRESS_REGISTERED_CITY: Symbol('ADDRESS_REGISTERED_CITY'),
  ADDRESS_REGISTERED_STREET: Symbol('ADDRESS_REGISTERED_STREET'),
  ADDRESS_REGISTERED_BUILDING: Symbol('ADDRESS_REGISTERED_BUILDING'),
  ADDRESS_REGISTERED_APARTMENTS: Symbol('ADDRESS_REGISTERED_APARTMENTS'),
  ADDRESS_FACTUAL_COUNTRY: Symbol('ADDRESS_FACTUAL_COUNTRY'),
  ADDRESS_FACTUAL_REGION: Symbol('ADDRESS_FACTUAL_REGION'),
  ADDRESS_FACTUAL_CITY: Symbol('ADDRESS_FACTUAL_CITY'),
  ADDRESS_FACTUAL_STREET: Symbol('ADDRESS_FACTUAL_STREET'),
  ADDRESS_FACTUAL_BUILDING: Symbol('ADDRESS_FACTUAL_BUILDING'),
  ADDRESS_FACTUAL_APARTMENTS: Symbol('ADDRESS_FACTUAL_APARTMENTS'),
  ADDRESS_COPY_FROM_REGISTERED: Symbol('ADDRESS_COPY_FROM_REGISTERED'),
  ADDRESS_COPY_FROM_FACTUAL: Symbol('ADDRESS_COPY_FROM_FACTUAL'),
  PASSPORT_IMAGE: Symbol('PASSPORT_IMAGE'),
  PASSPORT_IMAGE_RESET: Symbol('PASSPORT_IMAGE_RESET'),
  PORTFOLIO_IMAGE: Symbol('PORTFOLIO_IMAGE'),
  PORTFOLIO_IMAGE_RESET: Symbol('PORTFOLIO_IMAGE_RESET'),
  MOBILE_PHONE_NUMBERS: Symbol('MOBILE_PHONE_NUMBERS'),
  ADDRESS_REGISTERED_PHONES: Symbol('ADDRESS_REGISTERED_PHONES'),
  ADDRESS_FACTUAL_PHONES: Symbol('ADDRESS_FACTUAL_PHONES'),
  WORK_PHONE_NUMBERS: Symbol('WORK_PHONE_NUMBERS'),
  EMAILS: Symbol('EMAILS'),
  OCCUPATION_INSTITUTION: Symbol('OCCUPATION_INSTITUTION'),
  OCCUPATION_POSITION: Symbol('OCCUPATION_POSITION'),
  MONTHLY_INCOME: Symbol('MONTHLY_INCOME'),
  IS_STUDENT: Symbol('IS_STUDENT'),
  COLLEGE_NAME: Symbol('COLLEGE_NAME'),
  COLLEGE_PROFESSION: Symbol('COLLEGE_PROFESSION'),
  COLLEGE_STARTED_AT: Symbol('COLLEGE_STARTED_AT'),
  COLLEGE_ENDED_AT: Symbol('COLLEGE_ENDED_AT'),
  RECOMMENDATIONS: Symbol('RECOMMENDATIONS'),
  REFERENCES: Symbol('REFERENCES'),
  VK: Symbol('VK'),
  FACEBOOK: Symbol('FACEBOOK'),
  YOUTUBE: Symbol('YOUTUBE'),
  VIMEO: Symbol('VIMEO'),
  INSTAGRAM: Symbol('INSTAGRAM'),
  WORK_WEBSITE: Symbol('WORK_WEBSITE'),
  PERSONAL_WEBSITE: Symbol('PERSONAL_WEBSITE'),
  ADDITIONAL_LINKS: Symbol('ADDITIONAL_LINKS'),
  SUBSCRIBE: Symbol('SUBSCRIBE'),
  FEEDBACK: Symbol('FEEDBACK'),
  AGREE_ON_AGREEMENT: Symbol('AGREE_ON_AGREEMENT'),
}

/* THE REDUCER */
/* Action interface definition is just next to this reducer */
export function reducer(state = INITIAL_STATE, action: Action): State {
  switch (action.type) {
    case actions.REPLACE:
      return action.data as State
    case actions.RESET:
      return INITIAL_STATE
    case actions.CURRENT_STEP:
      return { ...state, currentStep: action.data as number }
    case actions.FORM_ID:
      return { ...state, formId: action.data as string }
    case actions.USER_ID:
      return { ...state, userId: action.data as string }
    case actions.AGREED:
      return { ...state, agreed: action.data as boolean }
    case actions.PASSPORT_TYPE:
      return { ...state, passportType: action.data as string }
    case actions.CITIZENSHIP:
      return { ...state, citizenship: action.data as string }
    case actions.FIRST_NAME:
      return { ...state, firstName: action.data as string }
    case actions.LAST_NAME:
      return { ...state, lastName: action.data as string }
    case actions.PATRONYMIC:
      return { ...state, patronymic: action.data as string }
    case actions.BIRTH_DATE:
      return { ...state, birthDate: action.data as string }
    case actions.BIRTH_PLACE:
      return { ...state, birthPlace: action.data as string }
    case actions.PASSPORT_IAT:
      return { ...state, passportIAt: action.data as string }
    case actions.PASSPORT_SERIES:
      return { ...state, passportSeries: action.data as string }
    case actions.PASSPORT_NUMBER:
      return { ...state, passportNumber: action.data as string }
    case actions.PASSPORT_ISSUED_BY:
      return { ...state, passportIssuedBy: action.data as string }
    case actions.PASSPORT_AUTHORITY_CODE:
      return { ...state, passportAuthorityCode: action.data as string }
    case actions.ADDRESS_REGISTERED_DATE:
      return { ...state, addressRegisteredDate: action.data as string }
    case actions.ADDRESS_REGISTERED_COUNTRY:
      return { ...state, addressRegisteredCountry: action.data as string }
    case actions.ADDRESS_REGISTERED_REGION:
      return { ...state, addressRegisteredRegion: action.data as string }
    case actions.ADDRESS_REGISTERED_CITY:
      return { ...state, addressRegisteredCity: action.data as string }
    case actions.ADDRESS_REGISTERED_STREET:
      return { ...state, addressRegisteredStreet: action.data as string }
    case actions.ADDRESS_REGISTERED_BUILDING:
      return { ...state, addressRegisteredBuilding: action.data as string }
    case actions.ADDRESS_REGISTERED_APARTMENTS:
      return { ...state, addressRegisteredApartments: action.data as string }
    case actions.ADDRESS_FACTUAL_COUNTRY:
      return { ...state, addressFactualCountry: action.data as string }
    case actions.ADDRESS_FACTUAL_REGION:
      return { ...state, addressFactualRegion: action.data as string }
    case actions.ADDRESS_FACTUAL_CITY:
      return { ...state, addressFactualCity: action.data as string }
    case actions.ADDRESS_FACTUAL_STREET:
      return { ...state, addressFactualStreet: action.data as string }
    case actions.ADDRESS_FACTUAL_BUILDING:
      return { ...state, addressFactualBuilding: action.data as string }
    case actions.ADDRESS_FACTUAL_APARTMENTS:
      return { ...state, addressFactualApartments: action.data as string }
    case actions.PASSPORT_IMAGE:
      return {
        ...state,
        passportImages: [...state.passportImages, action.data as string],
      }
    case actions.PASSPORT_IMAGE_RESET:
      return {
        ...state,
        passportImages: [...INITIAL_STATE.passportImages],
      }
    case actions.PORTFOLIO_IMAGE:
      return {
        ...state,
        portfolioImages: [...state.portfolioImages, action.data as string],
      }
    case actions.PORTFOLIO_IMAGE_RESET:
      return {
        ...state,
        portfolioImages: [...INITIAL_STATE.portfolioImages],
      }
    case actions.ADDRESS_COPY_FROM_FACTUAL:
      return copyFromFactual(state)
    case actions.ADDRESS_COPY_FROM_REGISTERED:
      return copyFromRegistered(state)
    case actions.ADDRESS_REGISTERED_PHONES:
      return { ...state, addressRegisteredPhones: action.data as string[] }
    case actions.ADDRESS_FACTUAL_PHONES:
      return { ...state, addressFactualPhones: action.data as string[] }
    case actions.MOBILE_PHONE_NUMBERS:
      return { ...state, mobilePhoneNumbers: action.data as string[] }
    case actions.WORK_PHONE_NUMBERS:
      return { ...state, workPhoneNumbers: action.data as string[] }
    case actions.EMAILS:
      return { ...state, emails: action.data as string[] }
    case actions.OCCUPATION_INSTITUTION:
      return { ...state, occupationInstitution: action.data as string }
    case actions.OCCUPATION_POSITION:
      return { ...state, occupationPosition: action.data as string }
    case actions.MONTHLY_INCOME:
      return { ...state, monthlyIncome: action.data as string }
    case actions.IS_STUDENT:
      return { ...state, isStudent: action.data as boolean }
    case actions.COLLEGE_NAME:
      return { ...state, collegeName: action.data as string }
    case actions.COLLEGE_PROFESSION:
      return { ...state, collegeProfession: action.data as string }
    case actions.COLLEGE_STARTED_AT:
      return { ...state, collegeStartedAt: action.data as string }
    case actions.COLLEGE_ENDED_AT:
      return { ...state, collegeEndedAt: action.data as string }
    case actions.RECOMMENDATIONS:
      return { ...state, recommendations: action.data as Recommendation[] }
    case actions.REFERENCES:
      return { ...state, references: action.data as string[] }
    case actions.VK:
      return { ...state, vk: action.data as string[] }
    case actions.FACEBOOK:
      return { ...state, facebook: action.data as string[] }
    case actions.YOUTUBE:
      return { ...state, youtube: action.data as string[] }
    case actions.VIMEO:
      return { ...state, vimeo: action.data as string[] }
    case actions.INSTAGRAM:
      return { ...state, instagram: action.data as string[] }
    case actions.WORK_WEBSITE:
      return { ...state, workWebsite: action.data as string[] }
    case actions.PERSONAL_WEBSITE:
      return { ...state, personalWebsite: action.data as string[] }
    case actions.ADDITIONAL_LINKS:
      return { ...state, additionalLinks: action.data as string[] }
    case actions.SUBSCRIBE:
      return { ...state, subscribe: action.data as boolean }
    case actions.FEEDBACK:
      return { ...state, feedback: action.data as string }
    case actions.AGREE_ON_AGREEMENT:
      return { ...state, agreedOn: [...state.agreedOn, action.data as string] }
    default:
      return state
  }
}

export interface Action {
  type: symbol
  data: boolean | string | string[] | State | Recommendation[] | number
}

export interface Dispatch {
  (action: Action): void
}

export function getInitialState(localStorage: Storage): State {
  const savedState = loadState(INITIAL_STATE, localStorage)
  const weekAgo = daysAgo(7)
  // Earlier than
  if (new Date(savedState.createdAt) < weekAgo) {
    resetLocalStorage(localStorage)
    return INITIAL_STATE
  }
  return {
    ...savedState,
    currentStep: 0,
  }
}
