/*
  We use the uppy for uploads. Try not to change props after calling
  the component, because on every prop change it will create new
  Uppy instance wich will take memory.
  The Uppy instanse creates with useMemo call inside function component.
  This is so because we need to make one universal component to use it
  in multiple places, so we need multiple uppy instances.
*/

import React from 'react'

import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import uppyRussianLocale from '@uppy/locales/lib/ru_RU'
import uppyEnglishLocale from '@uppy/locales/lib/en_US'

import { Lang } from '../common-types'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './UppyCustomStyles.scss'

interface Props {
  lang: Lang
  uppy: Uppy.Uppy
}

export default function Upload({ lang, uppy }: Props) {
  return (
    <Dashboard
      uppy={uppy}
      showLinkToFileUploadResult={false}
      proudlyDisplayPoweredByUppy={false}
      // width={474.45}
      locale={
        {
          eng: uppyEnglishLocale,
          rus: uppyRussianLocale,
        }[lang]
      }
    />
  )
}
