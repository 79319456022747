import React, { useState } from 'react'
import ReactPhoneInput from 'react-phone-input-2'

import { useDefer } from '../helpers'
import { validateString } from '../validators'
import { Recommendation, L10n, Lang, ContentInput } from '../common-types'
import TextInput from './TextInput'
import CloseButton from './CloseButton'
import PlusSignIcon from './PlusSignIcon'

import 'react-phone-input-2/dist/style.css'

import styles from './RecommendationsInput.module.scss'

interface Props {
  lang: Lang
  recommendationsLabel: L10n
  recommendationsName: ContentInput
  recommendationsPhone: ContentInput
  recommendations: Recommendation[]
  setRecommendations: (r: Recommendation[]) => void
  deleteButton: L10n
  addFieldButton: L10n
  countries: {
    [key: string]: string
  }
}

interface InternalState {
  [key: string]: string
}

export default function PhoneInput({
  lang,
  recommendationsLabel,
  recommendationsName,
  recommendationsPhone,
  recommendations,
  setRecommendations,
  deleteButton,
  addFieldButton,
  countries,
}: Props) {
  // Set internal state to avoid unnecessary updates
  // There is always must be at least one input field
  const [state, setState]: [
    Recommendation[],
    React.Dispatch<React.SetStateAction<[string, string][]>>
  ] = useState(recommendations.length === 0 ? [['', '']] : recommendations)

  // Update upper state after some time on changes.
  // We manualy update state when delete field (see delete button onClick handler)
  const [inOnChangeInput, setInOnChangeInput] = useState(false)
  useDefer(() => {
    if (inOnChangeInput) {
      setInOnChangeInput(false)
      setRecommendations(state)
    }
  }, [state])

  function onChangeHandler(value: Recommendation, index: number): void {
    // Set state with new array of numbers.
    // New array is a copy of previous one with all indices kept
    // only current value changed under current index.
    // It is same as we set array[index] = newValue but instead
    // mutating old array we get new one.
    // Get parts of an array without current item
    const [firsPart, lastPart] = [
      // part before current item
      state.slice(0, index),
      // part after current item
      state.slice(index + 1),
    ]
    setState([...firsPart, value, ...lastPart])
    setInOnChangeInput(true)
  }

  const deleteText = deleteButton[lang]
  const addField = addFieldButton[lang]
  return (
    <section className={styles.container}>
      <h2 className={styles.label}>{recommendationsLabel[lang]}</h2>
      <ul className={styles.list}>
        {state.map(([name, phone], index) => (
          <li key={index} className={styles.listItem}>
            <div className={styles.inputs}>
              <label
                className={styles.nameLabel}
                htmlFor={`recommendations-name-${index}`}
              >
                <p className={styles.labelText}>
                  {recommendationsName.label[lang]}
                </p>
                <div className={styles.nameInputAndButtonWrapper}>
                  <input
                    id={`recommendations-name-${index}`}
                    type="text"
                    placeholder={recommendationsName.placeholder[lang]}
                    value={name}
                    className={styles.nameInput}
                    onChange={e => {
                      const { value } = e.target
                      onChangeHandler([value, phone], index)
                    }}
                    maxLength={200}
                  />
                  <button
                    title={deleteText}
                    aria-label={deleteText}
                    className={styles.deleteButton}
                    type="button"
                    onClick={() => {
                      if (state.length === 1) {
                        setRecommendations([['', '']])
                        setState([['', '']])
                        return
                      }
                      // Get parts of an array without current item
                      const [firsPart, lastPart] = [
                        // part before current item
                        state.slice(0, index),
                        // part after current item
                        state.slice(index + 1),
                      ]
                      const newState = [...firsPart, ...lastPart]
                      setRecommendations(newState)
                      setState(newState)
                    }}
                  >
                    <CloseButton />
                  </button>
                </div>
              </label>
              <label
                htmlFor={`recommendations-phone-input-${index}`}
                className={styles.phoneLabel}
              >
                <p className={styles.labelText}>
                  {recommendationsPhone.label[lang]}
                </p>
                <div
                  className={`${!false && styles.invalid} ${
                    styles.phoneInputAndButtonWrapper
                  }`.trim()}
                >
                  <ReactPhoneInput
                    inputExtraProps={{
                      id: `recommendations-phone-input-${index}`,
                    }}
                    containerClass={`react-tel-input ${styles.inputContainer}`}
                    inputClass={styles.input}
                    buttonClass={styles.dropDownButton}
                    buttonStyle={{
                      border: 'none',
                      borderRadius: 'none',
                      borderRight: '1px solid black',
                    }}
                    defaultCountry={'ru'}
                    localization={countries}
                    value={phone}
                    onChange={(newPhone: string) =>
                      onChangeHandler([name, newPhone], index)
                    }
                  />
                  <button
                    title={deleteText}
                    aria-label={deleteText}
                    className={styles.deleteButton}
                    type="button"
                    onClick={() => {
                      if (state.length === 1) {
                        setRecommendations([['', '']])
                        setState([['', '']])
                        return
                      }
                      // Get parts of an array without current item
                      const [firsPart, lastPart] = [
                        // part before current item
                        state.slice(0, index),
                        // part after current item
                        state.slice(index + 1),
                      ]
                      const newState = [...firsPart, ...lastPart]
                      setRecommendations(newState)
                      setState(newState)
                    }}
                  >
                    <CloseButton />
                  </button>
                </div>
              </label>
            </div>
            <button
              title={addField}
              aria-label={addField}
              disabled={state.length === 12}
              type="button"
              className={styles.addField}
              onClick={() => setState([...state, ['', '']])}
            >
              <PlusSignIcon />
            </button>
          </li>
        ))}
      </ul>
    </section>
  )
}
