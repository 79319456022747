import React, { useRef } from 'react'

import SelectInput from './SelectInput'
import { DateInputProps, ContentInput } from '../common-types'
import {
  generateId,
  fromISOdate,
  getDaysInMonth,
  zeroPaddedNumber,
} from '../helpers'

import styles from './DatePicker.module.scss'

function getValue(value: string | undefined): string {
  if (typeof value === 'string') {
    // if '--' or '2009--' or '-23-' or '2009--09' ish
    if (value.match(/^(\d{4})?-(\d{2})?-(\d{2})?$/g)) {
      return value
    }
  }
  return '--'
}

function DatePicker({
  lang,
  accountForm,
  accountFormKey,
  onChange,
  value,
  min,
  max,
  className,
  valid,
  required,
}: DateInputProps) {
  // idRef is used for label tag  ("htmlFor")
  const idRef = useRef(generateId(16))
  const currentDate = new Date()
  const maxDate = max ? fromISOdate(max) : currentDate
  const minDate = min
    ? fromISOdate(min)
    : new Date(currentDate.getFullYear() - 100, 0, 2)
  const invalidClassName = valid ? '' : styles.invalid
  const prefixClassName = className || ''
  const [selectedYear, selectedMonth, selectedDay] = getValue(value).split('-')
  const daysAmountInCurrentMonth = getDaysInMonth(
    parseInt(selectedMonth, 10) || 0,
    parseInt(selectedYear, 10) || 1972
  )
  // Prepare options for <SelectInput />
  // Fill list with possible date choices using JS range() implementation
  const daysList = [...Array(daysAmountInCurrentMonth).keys()].map(index => {
    const value = zeroPaddedNumber(index + 1)
    return { eng: value, rus: value }
  })
  const monthsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
    const value = zeroPaddedNumber(month)
    return { eng: value, rus: value }
  })
  const yearsList = [
    ...Array(maxDate.getFullYear() - minDate.getFullYear()).keys(),
  ].map(index => {
    // Make list in descending order using index
    const value = String(maxDate.getFullYear() - index)
    return { eng: value, rus: value }
  })
  if (!required) {
    ;[daysList, monthsList, yearsList].forEach(list =>
      list.unshift({ eng: '', rus: '' })
    )
  }

  // On change handler
  const handleChange = (newValue: string, type: 'YEAR' | 'MONTH' | 'DAY') => {
    let result = ''
    switch (type) {
      case 'YEAR':
        result = `${newValue}-${selectedMonth}-${selectedDay}`
        break
      case 'MONTH':
        result = `${selectedYear}-${newValue}-${selectedDay}`
        break
      case 'DAY':
        result = `${selectedYear}-${selectedMonth}-${newValue}`
        break
      default:
        throw new Error(
          'Invalid date part type. Must be either: DAY, MONTH or YEAR.'
        )
    }
    if (result === '--') {
      result = ''
    }
    onChange(result)
  }

  return (
    <label
      htmlFor={idRef.current}
      className={`${prefixClassName} ${styles.label} ${invalidClassName}`.trim()}
    >
      {(accountForm[accountFormKey] as ContentInput).label[lang]}
      {required && <span className={styles.required}>&nbsp;*</span>}
      <div className={styles.inputsContainer}>
        <SelectInput
          customInputId={idRef.current}
          lang={lang}
          noOptionsMessage={accountForm.noOptionsMessage}
          content={{
            label: accountForm.selectDay,
            placeholder: { eng: '', rus: '' },
          }}
          onChange={value => handleChange(value, 'DAY')}
          value={selectedDay}
          options={daysList}
          className={styles.input}
        />

        <SelectInput
          lang={lang}
          noOptionsMessage={accountForm.noOptionsMessage}
          content={{
            label: accountForm.selectMonth,
            placeholder: { eng: '', rus: '' },
          }}
          onChange={value => handleChange(value, 'MONTH')}
          value={selectedMonth}
          options={monthsList}
          className={styles.input}
        />

        <SelectInput
          lang={lang}
          noOptionsMessage={accountForm.noOptionsMessage}
          content={{
            label: accountForm.selectYear,
            placeholder: { eng: '', rus: '' },
          }}
          onChange={value => handleChange(value, 'YEAR')}
          value={selectedYear}
          options={yearsList}
          className={styles.input}
        />
      </div>
    </label>
  )
}

export default DatePicker
