/* SUMMARY PAGE */

import React, { useRef, useEffect } from 'react'
import Textarea from 'react-textarea-autosize'

import { Lang, Country } from '../common-types'
import { State, Dispatch, actions } from '../state-actions-reducers'
import AccountForm from '../content/account-form.types'
import SummaryItem from './SummaryItem'
import MultipleSummary from './MultipleSummary'

import styles from './ScreenSeven.module.scss'
import { trimForm } from '../validators'

interface Props {
  lang: Lang
  state: State
  accountForm: AccountForm
  allCountries: Country[]
  setStep: (n: number) => void
  dispatch: Dispatch
}

/* REACT COMPONENT */
export default function ScreenSeven({
  lang,
  state,
  accountForm,
  setStep,
  allCountries,
  dispatch,
}: Props) {
  const [stepWord] = accountForm.stepsText[lang].split('%step%')
  const fullName = `${state.firstName} ${state.lastName} ${state.patronymic}`
  const allCountriesRef = useRef(
    allCountries.reduce(
      (result: string[], country) => {
        let newResult = [...result]
        if (country.id === state.citizenship) {
          newResult = [country[lang], newResult[1], newResult[2]]
        }
        if (country.id === state.addressRegisteredCountry) {
          newResult = [newResult[0], country[lang], newResult[2]]
        }
        if (country.id === state.addressFactualCountry) {
          newResult = [newResult[0], newResult[1], country[lang]]
        }
        return newResult
      },
      ['', '', '']
    )
  )

  const [
    citizenship,
    addressRegisteredCountry,
    addressFactualCountry,
  ] = allCountriesRef.current

  // Trim form, to remove dummy fields
  useEffect(() => {
    dispatch({
      type: actions.REPLACE,
      data: trimForm(state),
    })
  }, [])

  return (
    <main className={styles.main}>
      {/* STEP 1 */}
      <h1 className={styles.mainHeader}>{accountForm.title[lang]}</h1>
      <article className={styles.article}>
        <div className={styles.headerWrapper}>
          <h3 className={styles.header}>{`${stepWord} 1`}</h3>
          <button
            onClick={() => setStep(1)}
            className={styles.toStepButton}
            type="button"
          >
            {accountForm.summaryGoToButton[lang]}
          </button>
        </div>
        <div className={styles.colsWrapper}>
          <div className={styles.leftCol}>
            <h3 className={styles.subHeader}>
              {accountForm.summaryPersonalDataTitle[lang]}
            </h3>
            <MultipleSummary
              fieldNames={[
                // [label value]
                ['firstName', 'fullName'],
                ['birthDate', 'birthDate'],
                ['birthPlace', 'birthPlace'],
                ['citizenshipInput', 'citizenship'],
              ]}
              lang={lang}
              accountForm={accountForm}
              state={{ ...state, citizenship, fullName }}
            />
          </div>
          {/* DOCUMENT */}
          <div className={styles.rightCol}>
            <h3 className={styles.subHeader}>
              {accountForm.passportTitle[lang]}
            </h3>
            <MultipleSummary
              fieldNames={[
                // [label, value]
                ['passportTypeInput', 'passportType'],
                ['passportSeries', 'passportSeries'],
                ['passportNumber', 'passportNumber'],
                ['passportIssuedBy', 'passportIssuedBy'],
                ['passportIAt', 'passportIAt'],
                ['passportAuthorityCode', 'passportAuthorityCode'],
              ]}
              lang={lang}
              accountForm={accountForm}
              state={state}
            />
          </div>
        </div>
      </article>

      {/* STEP 2 */}
      <article className={styles.article}>
        <div className={styles.headerWrapper}>
          <h3 className={styles.header}>{`${stepWord} 2`}</h3>
          <button
            onClick={() => setStep(2)}
            className={styles.toStepButton}
            type="button"
          >
            {accountForm.summaryGoToButton[lang]}
          </button>
        </div>
        <div className={styles.colsWrapper}>
          {/* REGISTERED ADDRESS */}

          <div className={styles.leftCol}>
            <h3 className={styles.subHeader}>
              {accountForm.addressRegisteredTitle[lang]}
            </h3>
            {/* SHOW ONLY IF ADDRESS ACTUALY FILLED */}
            {(state.addressRegisteredCity || state.addressRegisteredStreet) && (
              <MultipleSummary
                fieldNames={[
                  ['addressCountry', 'addressRegisteredCountry'],
                  ['addressRegion', 'addressRegisteredRegion'],
                  ['addressCity', 'addressRegisteredCity'],
                  ['addressStreet', 'addressRegisteredStreet'],
                  ['addressBuilding', 'addressRegisteredBuilding'],
                  ['addressApartments', 'addressRegisteredApartments'],
                  ['addressRegisteredDate', 'addressRegisteredDate'],
                ]}
                lang={lang}
                state={{ ...state, addressRegisteredCountry }}
                accountForm={accountForm}
              />
            )}
          </div>

          {/* FACTUAL ADDRESS */}
          <div className={styles.rightCol}>
            <h3 className={styles.subHeader}>
              {accountForm.addressFactualTitle[lang]}
            </h3>
            <MultipleSummary
              fieldNames={[
                ['addressCountry', 'addressFactualCountry'],
                ['addressRegion', 'addressFactualRegion'],
                ['addressCity', 'addressFactualCity'],
                ['addressStreet', 'addressFactualStreet'],
                ['addressBuilding', 'addressFactualBuilding'],
                ['addressApartments', 'addressFactualApartments'],
              ]}
              lang={lang}
              state={{ ...state, addressFactualCountry }}
              accountForm={accountForm}
            />
          </div>
        </div>
      </article>

      {/* STEP 3 */}
      <article className={styles.article}>
        <div className={styles.headerWrapper}>
          <h3 className={styles.header}>{`${stepWord} 3`}</h3>
          <button
            onClick={() => setStep(3)}
            className={styles.toStepButton}
            type="button"
          >
            {accountForm.summaryGoToButton[lang]}
          </button>
        </div>
        <div className={styles.singleCol}>
          <h4 className={styles.uploadsHeader}>
            {accountForm.uploadPassportHeader[lang]}
          </h4>
          <ul className={styles.imagesList}>
            {state.passportImages.map(image => (
              <li key={image} className={styles.imagesListItem}>
                <img
                  src={image}
                  alt={accountForm.uploadPassportHeader[lang]}
                  className={styles.image}
                />
              </li>
            ))}
          </ul>
        </div>
      </article>

      {/* STEP 4 */}
      <article className={styles.article}>
        <div className={styles.headerWrapper}>
          <h3 className={styles.header}>{`${stepWord} 4`}</h3>
          <button
            onClick={() => setStep(4)}
            className={styles.toStepButton}
            type="button"
          >
            {accountForm.summaryGoToButton[lang]}
          </button>
        </div>
        <div className={styles.colsWrapper}>
          <div className={styles.leftCol}>
            <MultipleSummary
              fieldNames={[
                ['email', 'emails'],
                ['mobilePhoneNumber', 'mobilePhoneNumbers'],
                ['addressRegisteredPhone', 'addressRegisteredPhones'],
                ['addressFactualPhone', 'addressFactualPhones'],
              ]}
              lang={lang}
              accountForm={accountForm}
              state={state}
            />
          </div>
          <div className={styles.rightCol}>
            <MultipleSummary
              fieldNames={[
                ['occupationInstitution', 'occupationInstitution'],
                ['occupationPosition', 'occupationPosition'],
                ['workPhoneNumber', 'workPhoneNumbers'],
                ['monthlyIncomeInputText', 'monthlyIncome'],
              ]}
              lang={lang}
              accountForm={accountForm}
              state={state}
            />
            {state.isStudent && (
              <div className={styles.student}>
                <MultipleSummary
                  fieldNames={[
                    ['collegeName', 'collegeName'],
                    ['collegeProfession', 'collegeProfession'],
                    ['collegeStartedAt', 'collegeStartedAt'],
                    ['collegeEndedAt', 'collegeEndedAt'],
                  ]}
                  lang={lang}
                  accountForm={accountForm}
                  state={state}
                />
              </div>
            )}
          </div>
        </div>
      </article>

      {/* STEP 5 */}
      <article className={styles.article}>
        <div className={styles.headerWrapper}>
          <h3 className={styles.header}>{`${stepWord} 5`}</h3>
          <button
            onClick={() => setStep(5)}
            className={styles.toStepButton}
            type="button"
          >
            {accountForm.summaryGoToButton[lang]}
          </button>
        </div>
        <div className={styles.singleCol}>
          <div className={styles.recommendations}>
            <p className={styles.recommendationsLabel}>
              {accountForm.recommendationsLabel[lang]}
            </p>
            {state.recommendations.map((recommendation, index) => {
              const [name, phone] = recommendation
              return (
                <p key={index} className={styles.recommendation}>
                  {name}:&nbsp;{phone}
                </p>
              )
            })}
          </div>
          <SummaryItem
            label={accountForm.summaryReferencesLabel[lang]}
            value={state.references}
          />
          <MultipleSummary
            fieldNames={[
              ['vk', 'vk'],
              ['facebook', 'facebook'],
              ['youtube', 'youtube'],
              ['vimeo', 'vimeo'],
              ['instagram', 'instagram'],
              ['workWebsite', 'workWebsite'],
              ['personalWebsite', 'personalWebsite'],
              ['additionalLinks', 'additionalLinks'],
            ]}
            lang={lang}
            accountForm={accountForm}
            state={state}
          />
        </div>
      </article>

      {/* STEP 6 */}

      <article className={styles.article}>
        <div className={styles.headerWrapper}>
          <h3 className={styles.header}>{`${stepWord} 6`}</h3>
          <button
            onClick={() => setStep(6)}
            className={styles.toStepButton}
            type="button"
          >
            {accountForm.summaryGoToButton[lang]}
          </button>
        </div>
        <div className={styles.singleCol}>
          <h4 className={styles.uploadsHeader}>
            {accountForm.uploadPortfolioHeader[lang]}
          </h4>
          {state.portfolioImages.length > 0 ? (
            <ul className={styles.imagesList}>
              {state.portfolioImages.map(image => (
                <li key={image} className={styles.imagesListItem}>
                  <img
                    src={image}
                    alt={accountForm.uploadPortfolioHeader[lang]}
                    className={styles.image}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <p className={styles.noPortfolioImagesMessage}>
              {accountForm.summaryNoImagesMessage[lang]}
            </p>
          )}
        </div>
      </article>

      {/* FEEDBACK */}
      <article className={styles.article}>
        <label htmlFor="feedback-textarea-id" className={styles.feedbackLabel}>
          <div className={styles.headerWrapper}>
            <h3 className={styles.feedbackHeader}>
              {accountForm.feedback.label[lang]}
            </h3>
          </div>
          <div className={styles.singleCol}>
            <Textarea
              id="feedback-textarea-id"
              className={styles.feedbackInput}
              onChange={({ target }) => {
                dispatch({
                  type: actions.FEEDBACK,
                  data: target.value,
                })
              }}
              maxRows={50}
              placeholder={accountForm.feedback.placeholder[lang]}
            />
          </div>
        </label>
      </article>

      {/* <div className={styles.subscribeContainer}>
        <label
          htmlFor="subscribe-checkbox-id"
          className={styles.subscribeLabel}
        >
          <input
            id="subscribe-checkbox-id"
            type="checkbox"
            className={styles.subscribeInput}
            onChange={({ target }) => {
              dispatch({
                type: actions.SUBSCRIBE,
                data: target.checked,
              })
            }}
          />
          &nbsp;{accountForm.subscribe.label[lang]}
        </label>
      </div> */}
    </main>
  )
}
